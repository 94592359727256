import React, { useState } from 'react'
import {
  Badge,
  Box,
  Button,
  Checkbox,
  FormControl,
  Input,
  Select,
  Table,
  Tbody,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

import * as actions from '../../../redux/actions'
import Config from '../../../config'

export default function ChartMakingComponent({
  index,
  stationData,
  chartData,
}) {
  const { t } = useTranslation()
  const [isEditorMode, setIsEditorMode] = useState(false)
  const { register, handleSubmit } = useForm()
  const [rendered, setRendered] = useState(false)
  const [chartSource, setChartSource] = useState(chartData.chartSource)
  const [isAllowSensorOnSituation, setIsAllowSensorOnSituation] = useState(
    chartData.labelOnSituation?.enable
  )
  const [isAllowLineOfAlert, setIsAllowLineOfAlert] = useState(
    chartData.lineOfAlert?.enable
  )
  const [isLinkToThreshold, setIsLinkToThreshold] = useState(
    chartData?.lineOfAlert?.linkSensorThreshold
  )
  const [fixedYAxis, setFixedYAxis] = useState(chartData?.fixedYAxis?.enable)

  const dispatch = useDispatch()

  const genRefStringList = () => {
    return _.map(stationData.sensors, (sensor) => (
      <option value={sensor.refString}>{sensor.name}</option>
    ))
  }
  // console.log('variable', stationData.variables)
  const genVariableList = () => {
    return _.map(stationData.variables, (variable) => (
      <option value={variable._id}>{variable.variableName}</option>
    ))
  }

  // Merged the Sensor Data and Make new Station Data to Database
  const prepareUpdateDatabase = (data) => {
    const selectedChart = stationData.charts[index]
    const mergedChartData = _.merge(selectedChart, data)
    let editedStation = stationData
    editedStation.charts[index] = mergedChartData
    dispatch(actions.stationPut(stationData._id, editedStation)).then(() => {
      dispatch(actions.stationAll())
    })
    setIsEditorMode(false)
  }

  const prepareDeleteChart = () => {
    let confirm = window.confirm('ยืนยันการลบตัวแปร')
    if (confirm) {
      let editedStation = stationData
      if (editedStation.charts) {
        editedStation.charts.splice(index, 1)
      }
      dispatch(actions.stationPut(stationData._id, editedStation)).then(() => {
        dispatch(actions.stationAll())
      })
      setIsEditorMode(false)
    }
  }

  const renderDisplayMode = () => (
    <div>
      <div className="flex flex-wrap ">
        <div className="lg:w-2/3">
          <ul className="list-inside list-disc ml-6">
            <li>
              <span className="text-sm font-semibold text-gray-700">
                {t('graph.valueSource')} :{' '}
              </span>
              <span className="text-sm font-semibold text-blue-700">
                {chartData.chartSource}
              </span>
            </li>
            <li>
              <span className="text-sm font-semibold text-gray-700">
                {t('graph.displayedValue')} :{' '}
              </span>
              <span className="text-sm font-semibold text-blue-700">
                {chartData.chartSource ===
                Config.TypeOfSource.GaugeSource.sensor
                  ? chartData.chartSensorRef
                  : chartData.chartLabel}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )

  const renderEditorMode = () => (
    <div>
      <FormControl>
        <label className="text-sm font-bold">{t('graph.graphName')}</label>
        <Input
          type="text"
          name="chartLabel"
          size="sm"
          defaultValue={chartData.chartLabel}
          ref={register}
        />
      </FormControl>
      <FormControl>
        <label className="text-sm font-bold">{t('graph.graphType')}</label>
        <Select
          defaultValue={chartData.chartType}
          name="chartType"
          ref={register}
          size="sm"
        >
          <option value="">{t('graph.graphTypeSelect')}</option>
          <option value={Config.ComponentType.ChartType.area}>
            {t('graph.typeArea')}
          </option>
          <option value={Config.ComponentType.ChartType.line}>
            {t('graph.typeLine')}
          </option>
          <option value={Config.ComponentType.ChartType.combine}>
            {t('graph.typeMultiLine')}
          </option>
          {/* <option
                              value={
                                  Config.ComponentType.ChartType.bar
                              }
                          >
                              กราฟแท่ง
                          </option> */}
        </Select>
      </FormControl>
      <FormControl>
        <label className="text-sm font-bold">{t('graph.valueSource')}</label>
        <Select
          defaultValue={chartData.chartSource}
          value={chartSource}
          onChange={(event) => {
            setChartSource(event.target.value)
            setRendered(!rendered)
          }}
          name="chartSource"
          ref={register}
          size="sm"
        >
          <option value=""> {t('graph.valueSourceSelect')}</option>
          <option value={Config.TypeOfSource.GaugeSource.sensor}>
            {t('setting.sensor')}
          </option>
          <option value={Config.TypeOfSource.GaugeSource.variable}>
            {t('setting.variable')}
          </option>
        </Select>
      </FormControl>

      {chartSource === Config.TypeOfSource.GaugeSource.sensor ? (
        <FormControl>
          <label className="text-sm font-bold">
            {t('sensor.selectedSensor')}
          </label>
          <Select
            defaultValue={chartData.chartSensorRef}
            name="chartSensorRef"
            ref={register}
            size="sm"
          >
            <option value="">{t('sensor.selectedSensor')}</option>
            {genRefStringList()}
          </Select>
        </FormControl>
      ) : (
        <FormControl>
          <label className="text-sm font-bold">
            {t('sensor.selectedVariable')}
          </label>
          <Select
            defaultValue={chartData.chartVariableId}
            name="chartVariableId"
            ref={register}
            size="sm"
          >
            <option value="">{t('sensor.selectedVariable')}</option>
            {genVariableList()}
          </Select>
        </FormControl>
      )}
      <FormControl>
        <label className="text-sm font-bold">{t('graph.yAxisName')}</label>
        <Input
          type="text"
          name="valueLabel"
          size="sm"
          defaultValue={chartData.valueLabel}
          ref={register}
        />
        <label className="text-sm font-bold">{t('graph.yAxisUnit')}</label>
        <Input
          type="text"
          name="valueUnit"
          size="sm"
          defaultValue={chartData.valueUnit}
          ref={register}
        />
      </FormControl>
      <FormControl>
        <label className="text-sm font-bold">{t('graph.graphSlope')}</label>
        <Select
          defaultValue={chartData.chartCurve}
          name="chartCurve"
          ref={register}
          size="sm"
        >
          <option value="">{t('graph.graphSlopeSelect')}</option>
          <option value={Config.ComponentType.ChartCurve.smooth}>
            {t('graph.graphSmooth')}
          </option>
          <option value={Config.ComponentType.ChartCurve.straight}>
            {t('graph.line')}
          </option>
          <option value={Config.ComponentType.ChartCurve.stepline}>
            {t('graph.step')}
          </option>
        </Select>
      </FormControl>
      <FormControl>
        <Checkbox
          defaultChecked={chartData.labelOnSituation?.enable}
          name="labelOnSituation.enable"
          ref={register}
          onChange={(event) =>
            setIsAllowSensorOnSituation(event.target.checked)
          }
        >
          {t('graph.insteadValueWithWord')}
        </Checkbox>
        <br />
        {isAllowSensorOnSituation ? (
          <>
            <label className="text-sm font-bold">
              {t('graph.whenValue')} <Badge colorScheme="green">True</Badge>{' '}
            </label>
            <Input
              type="text"
              name="labelOnSituation.labelOnHigh"
              size="sm"
              defaultValue={chartData.labelOnSituation?.labelOnHigh}
              ref={register}
            />
            <label className="text-sm font-bold">
              {t('graph.whenValue')} <Badge colorScheme="red">False</Badge>{' '}
            </label>
            <Input
              size="sm"
              type="text"
              name="labelOnSituation.labelOnLow"
              defaultValue={chartData.labelOnSituation?.labelOnLow}
              ref={register}
            />
          </>
        ) : (
          <div></div>
        )}
        <FormControl>
          <Checkbox
            defaultChecked={chartData.lineOfAlert?.enable}
            name="lineOfAlert.enable"
            ref={register}
            onChange={(event) => setIsAllowLineOfAlert(event.target.checked)}
          >
            แสดงเส้นสำหรับแจ้งเตือน
          </Checkbox>
        </FormControl>
        {isAllowLineOfAlert && (
          <div>
            <FormControl>
              {chartSource === Config.TypeOfSource.GaugeSource.sensor && (
                <div>
                  <FormControl>
                    <Checkbox
                      defaultChecked={
                        chartData.lineOfAlert?.linkSensorThreshold
                      }
                      name="lineOfAlert.linkSensorThreshold"
                      ref={register}
                      onChange={(event) =>
                        setIsLinkToThreshold(event.target.checked)
                      }
                    >
                      ลิ้งค์ค่าจากเทรชโฮลด์ของเซนเซอร์
                    </Checkbox>
                  </FormControl>
                </div>
              )}
              {!isLinkToThreshold && (
                <div>
                  <label className="text-sm font-bold">เทรชโฮลด์บน</label>
                  <Input
                    size="sm"
                    type="text"
                    name="lineOfAlert.higher"
                    defaultValue={chartData.lineOfAlert?.higher}
                    ref={register}
                  />
                  <label className="text-sm font-bold">เทรชโฮลด์ล่าง</label>
                  <Input
                    size="sm"
                    type="text"
                    name="lineOfAlert.lower"
                    defaultValue={chartData.lineOfAlert?.lower}
                    ref={register}
                  />
                </div>
              )}
            </FormControl>
          </div>
        )}
      </FormControl>
      <FormControl>
        <Checkbox
          defaultChecked={chartData.fixedYAxis?.enable}
          name="fixedYAxis.enable"
          ref={register}
          onChange={(event) => setFixedYAxis(event.target.checked)}
        >
          ตั้งค่าแกน Y เป็นค่าคงที่
        </Checkbox>
      </FormControl>
      {fixedYAxis && (
        <FormControl>
          <label className="text-sm font-bold">ขอบบน</label>
          <Input
            size="sm"
            type="text"
            name="fixedYAxis.upper"
            defaultValue={chartData.fixedYAxis?.upper}
            ref={register}
          />
          <label className="text-sm font-bold">ขอบล่าง</label>
          <Input
            size="sm"
            type="text"
            name="fixedYAxis.lower"
            defaultValue={chartData.fixedYAxis?.lower}
            ref={register}
          />
        </FormControl>
      )}
    </div>
  )

  return (
    <Box borderRadius="lg" padding="4" className="bg-white my-2">
      <form onSubmit={handleSubmit(prepareUpdateDatabase)}>
        <div className="flex gap-3">
          <div className="w-5/6">
            <h5 className="text-lg font-bold font-sans">
              {chartData.chartLabel}{' '}
              <Badge colorScheme="teal" size="sm">
                {chartData.chartType} Chart
              </Badge>{' '}
            </h5>
          </div>

          <div className="flex gap-1 ">
            {isEditorMode === true ? (
              <>
                <Button
                  size="sm"
                  type="button"
                  colorScheme="gray"
                  onClick={() => setIsEditorMode(false)}
                >
                  {t('utility.cancel')}
                </Button>{' '}
                <Button size="sm" colorScheme="green" type="submit">
                  {t('utility.save')}
                </Button>
              </>
            ) : (
              <>
                <Button
                  size="sm"
                  colorScheme="yellow"
                  onClick={() => setIsEditorMode(true)}
                  type="button"
                >
                  {t('utility.edit')}
                </Button>
                <Button
                  size="sm"
                  colorScheme="red"
                  type="button"
                  onClick={() => prepareDeleteChart()}
                >
                  {t('utility.delete')}
                </Button>
              </>
            )}
          </div>
        </div>

        <div className="w-full">
          {isEditorMode === true ? renderEditorMode() : renderDisplayMode()}
        </div>
      </form>
    </Box>
  )
}
