import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Switch,
    Input,
    FormControl,
    FormLabel,
    NumberInput,
} from '@chakra-ui/react'
import { conformsTo } from 'lodash'
import { database } from 'firebase-admin'

export default function ThresholdControl({ sensor, enableSwitchThreshold }) {
    const { t } = useTranslation()
    const { register, handleSubmit } = useForm()
    const submitThreshold = (data) => {
        console.log('Submit Threshold : ', data)
        enableSwitchThreshold(sensor.controlRefString, data)
    }
    return (
        <div className="w-full">
            <div className="lg:flex border-2 rounded-md">
                <div className="bg-gray-200 md:w-1/3">
                    <div className="items-center text-center px-4 py-6">
                        {sensor.name}
                    </div>
                </div>
                <div className="w-full flex md:w-2/3">
                    <div className="flex flex-row flex-warp text-center w-2/3">
                        <div className="flex flex-row flex-wrap self-center justify-center px-2">
                            <FormControl>
                                <label className="text-sm font-sans mx-2">
                                    {t('control.on')}
                                </label>
                                <Input
                                    type="number"
                                    name="on"
                                    size="sm"
                                    ref={register}
                                    defaultValue={sensor.threshold.on}
                                />
                            </FormControl>
                        </div>
                        <div className="flex flex-row flex-wrap self-center justify-center px-2">
                            <div className="px-2 self-center">
                                <FormControl>
                                    <label className="text-sm font-sans mx-2">
                                        {t('control.off')}
                                    </label>
                                    <Input
                                        type="number"
                                        name="off"
                                        size="sm"
                                        ref={register}
                                        defaultValue={sensor.threshold.off}
                                    />
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row flex-wrap justify-center self-center w-1/3">
                        <div className="justify-between text-center px-4 m-2">
                            <FormControl display="flex">
                                <Switch
                                    size="md"
                                    colorScheme="blue"
                                    name="enabled"
                                    ref={register}
                                    defaultChecked={sensor.threshold.enabled}
                                />
                            </FormControl>
                        </div>
                        <div className="mb-2">
                            <Button
                                size="sm"
                                colorScheme="green"
                                type="submit"
                                onClick={handleSubmit(submitThreshold)}
                            >
                                {t('utility.save')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
