import React from 'react'

import { WarningStatus, WarningColorClass } from '../../../config/ComponentType'

const AlarmBox = ({ warningStatus, unit, staticData, controlRange }) => {
  const fixData = (data) => {
    return Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
    }).format(data)
  }

  return (
    <div>
      {/** Symbolic */}
      {(warningStatus === WarningStatus.DANGER_HIGH ||
        warningStatus === WarningStatus.WARNING_HIGH) && (
        <div className="flex gap-2">
          <i
            className={`fas fa-caret-up text-xl  ${
              WarningColorClass[warningStatus] || ''
            }`}
          ></i>
          <div className="font-semibold">
            สูงกว่ากำหนด {fixData(staticData - controlRange?.upper)} {unit}
          </div>
        </div>
      )}

      {(warningStatus === WarningStatus.DANGER_LOW ||
        warningStatus === WarningStatus.WARNING_LOW) && (
        <div className="flex gap-2">
          <i
            className={`fas fa-caret-down text-xl ${
              WarningColorClass[warningStatus] || ''
            }`}
          ></i>
          <div className="font-semibold">
            ต่ำกว่ากำหนด {fixData(controlRange?.lower - staticData)} {unit}
          </div>
        </div>
      )}
    </div>
  )
}

export default AlarmBox
