import React from "react";
import { Box } from "@chakra-ui/react";
import _ from "lodash";
export default function MultiStepBooleanDisplay({
  stationData,
  graphLabel,
  multiLevelOperations,
  labelOnLow,
  labelOnHigh,
  highLevelColor,
  lowLevelColor,
}) {
  let opIndex = 0;
  let lastestOperationResult = false;
  console.log("ขนาดของ Multilevel Operation " + _.size(multiLevelOperations));

  //For All Operation
  for (opIndex = 0; opIndex < _.size(multiLevelOperations); opIndex++) {
    console.log("ทำงานใน Operation ที่ " + opIndex);
    //Denote Value from Multi Level Operator
    const leftVariableName = multiLevelOperations[opIndex].leftSensorRef;
    const rightVariableName = multiLevelOperations[opIndex].rightSensorRef;
    const operation = multiLevelOperations[opIndex].operation;

    // RIGHT WING

    console.log("กำลังหาตัวแปรทางด้านขวา");
    let rightBooleanStatus = false;
    console.log("ตัวแปรด้านขวา เป็นเซ็นเซอร์");
    const rightSensor = _.find(
      stationData.sensors,
      (sensor) => sensor.refString === rightVariableName
    );
    if (rightSensor === undefined) {
      console.log("หาเซ็นเซอร์ของคุณไม่พบ");
    } else {
      rightBooleanStatus = rightSensor.booleanStatus;
    }

    // LEFT WING

    console.log("กำลังหาเซ็นเซอร์ด้านซ้าย");
    if (leftVariableName !== null) {
      console.log("มีการใส่ตัวแปรด้านซ้าย");

      let leftBooleanStatis = 0;
      console.log("ค่าตัวแปรมาจากเซ็นเซอร์");
      const leftSensor = _.find(
        stationData.sensors,
        (sensor) => sensor.refString === leftVariableName
      );
      if (leftSensor === undefined) {
        console.log("หาเซ็นเซอร์ด้านซ้ายไม่เจอ");
      } else {
        leftBooleanStatis = leftSensor.booleanStatus;
        console.log("ค่าเซ็นเซอร์ด้านซ้ายมีค่า " + leftBooleanStatis);
      }

      // Update Data
      console.log("ทำการดำเนินการ");
      switch (operation) {
        case "AND":
          console.log("AND");
          lastestOperationResult = leftBooleanStatis && rightBooleanStatus;
          break;
        case "OR":
          console.log("OR");
          lastestOperationResult = leftBooleanStatis || rightBooleanStatus;
          break;
        case "NOR":
          console.log("NOR");
          lastestOperationResult = !(leftBooleanStatis || rightBooleanStatus);
          break;
        case "NAND":
          console.log("NAND");
          lastestOperationResult = !(leftBooleanStatis && rightBooleanStatus);
          break;
        default:
          break;
      }
    } else {
      // No Variable Addes (use latest value)
      console.log("ไม่มีการใส่ค่าตัวแปรด้านซ้าย ");
      console.log("กำลังคำนวณค่า");
      switch (operation) {
        case "AND":
          lastestOperationResult = lastestOperationResult && rightBooleanStatus;
          break;
        case "OR":
          lastestOperationResult = lastestOperationResult || rightBooleanStatus;
          break;
        case "NOR":
          lastestOperationResult = !(
            lastestOperationResult || rightBooleanStatus
          );
          break;
        case "NAND":
          lastestOperationResult = !(
            lastestOperationResult && rightBooleanStatus
          );
          break;
        default:
          break;
      }
    }
  }
  console.log("ค่าท้ายสุดจะเป็น", lastestOperationResult);
  const highColor = "text-" + highLevelColor + "-500";
  const lowColor = "text-" + lowLevelColor + "-500";
  return (
    <Box
      maxW='sm'
      borderWidth='1px'
      borderRadius='lg'
      className=' ml-4 my-2 p-2 w-full  md:w-full  h-64'
    >
      <h5 className='font-bold text-2xl'> {graphLabel} </h5>
      <div>
        {lastestOperationResult === true ? (
          <>
            {" "}
            <h1 className={" font-bold text-4xl mb-0 " + highColor}>
              {" "}
              {labelOnHigh}{" "}
            </h1>
          </>
        ) : (
          <h1 className={" font-bold text-4xl mb-0 " + lowColor}>
            {" "}
            {labelOnLow}{" "}
          </h1>
        )}
      </div>
    </Box>
  );
}
