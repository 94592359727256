import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'

import _ from 'lodash'
import {
  Badge,
  Box,
  Button,
  Switch,
  useDisclosure,
  FormLabel,
  FormControl,
} from '@chakra-ui/react'
import { useSelector, useDispatch } from 'react-redux'
import FormComponent from '../../../../components/Form/FormComponent'

import SpinnerLoading from '../../../../components/Loading/SpinnerLoading'
import { useTranslation } from 'react-i18next'

import { useParams, Link, useHistory } from 'react-router-dom'
import * as actions from '../../../../redux/actions'
import SubFormList from '../Components/SubFormList'
import ModalPeriod from '../../../../components/Modal/ModalPeriod'

export default function EditForm() {
  const history = useHistory()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const params = useParams()
  const station = useSelector((state) => state.station)
  const forms = useSelector((state) => state.form)
  const dispatch = useDispatch()
  const [selectedForm, setSelectedForm] = useState({})
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { control, setValue, handleSubmit } = useForm({})
  useEffect(() => {
    dispatch(actions.stationGet(params.stationId)).then(() =>
      setIsLoading(true)
    )
    dispatch(actions.formGet(params?.formId))

    return () => {}
  }, [params])
  const onOpenSwitch = async (value) => {
    await dispatch(
      actions.formPut(params?.formId, {
        'inputWithSensor.statusGetwithSensor': value,
      })
    )
    await dispatch(actions.formGet(params?.formId))
  }
  const genFormComponentList = () => {
    return (
      <div>
        <div className="flex justify-start gap-3 p-4">
          <Link
            to={`/system/stations/form/add/${params.stationId}/${params.formId}`}
          >
            <Button colorScheme="blue" variant="solid" size="sm">
              {t('utility.add')}
            </Button>
          </Link>
          <Link>
            <Button colorScheme="yellow" variant="solid" size="sm">
              จัดเรียง
            </Button>
          </Link>
          <Link
            to={`/system/stations/report/${params.stationId}/${params.formId}`}
          >
            <Button colorScheme="green" variant="solid" size="sm">
              จัดการรูปแบบรายงาน
            </Button>
          </Link>
          <Button
            size="sm"
            colorScheme="red"
            type="button"
            onClick={() => prepateDeleteForm()}
          >
            ลบรายการฟอร์ม
          </Button>
        </div>

        {forms?.inputWithSensor?.status && (
          <div className="px-4 flex lg:w-1/2  ">
            <Box
              borderRadius="lg"
              padding="4"
              className="bg-white my-2 overflow-x-auto w-full "
            >
              <div className="flex justify-between w-full ">
                <Badge colorScheme="red" sx={{ fontSize: 16 }}>
                  <div className="p-2">รับค่าโดยเซ็นเซอร์</div>
                </Badge>
                <div>
                  <FormControl display="flex" alignItems="center">
                    <Switch
                      id="email-alerts"
                      defaultChecked={
                        forms?.inputWithSensor?.statusGetwithSensor
                      }
                      onChange={(e) => onOpenSwitch(e.target.checked)}
                    />
                    <FormLabel htmlFor="email-alerts" mb="0" m="2" size="lg">
                      เปิด
                    </FormLabel>
                  </FormControl>
                </div>
              </div>
              <h1 className="py-2 ">
                ดึงค่าทุก {forms?.inputWithSensor?.period} นาที{' '}
              </h1>
              <Button
                onClick={onOpen}
                colorScheme="yellow"
                variant="solid"
                size="sm"
              >
                แก้ไข
              </Button>
            </Box>
          </div>
        )}
        <div className="p-4 w-full">
          {_.map(forms?.components, (eachComponent, index) => {
            return (
              <div key={index}>
                <FormComponent
                  componentData={eachComponent}
                  selectedForm={forms}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  const handleEditTimeSubmit = async (data) => {
    const confirm = window.confirm('ยืนยันแก้ไขข้อมูล')
    if (confirm) {
      const dataSubmit = {
        inputWithSensor: { period: data?.period, status: true },
      }
      await dispatch(actions.formPut(params?.formId, dataSubmit))
      await dispatch(actions.formGet(params?.formId))
      onClose()
    }
  }

  const prepateDeleteForm = async () => {
    const confirm = window.confirm('ยืนยันการลบรายการฟอร์ม')
    if (confirm) {
      await dispatch(actions.formDelete(params?.formId))
      await dispatch(actions.formAll({}))
      history.goBack()
    }
  }
  const renderSubForm = () => (
    <div>
      <SubFormList form={forms} prepateDeleteForm={prepateDeleteForm} />
    </div>
  )

  if (forms?.isLoading && !forms.isCompleted) {
    return <SpinnerLoading />
  }

  return (
    <div className="relative flex flex-col min-w-0 break-words bg-gray-100 w-full mb-6 shadow-lg rounded">
      <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
        <div className="flex flex-wrap items-center">
          <div className="relative w-full max-w-full flex-grow flex-1">
            <h6 className="uppercase text-gray-500 mb-1 text-xs font-semibold font-sans">
              {t('setting.systemManagement')}
            </h6>
            <h2 className="text-gray-800 text-2xl font-bold font-sans">
              ฟอร์ม {forms?.name} ในสถานี {station?.name}
            </h2>
          </div>
        </div>
      </div>
      {forms?.subForm_status ? renderSubForm() : genFormComponentList()}
      <form onSubmit={handleSubmit(handleEditTimeSubmit)}>
        <ModalPeriod
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          control={control}
          Controller={Controller}
          form={forms}
          setValue={setValue}
          handleSubmit={handleSubmit}
          submitedFunction={handleEditTimeSubmit}
        />
      </form>
    </div>
  )
}
