/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-use-before-define */
import React, { useState } from 'react'

import {
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  Button,
  Stack,
  Modal,
  CheckboxGroup,
  ButtonModal,
  ModalHeader,
  ModalBody,
  ModalOverlay,
  ModalFooter,
  ModalContent,
  useDisclosure,
} from '@chakra-ui/react'

import * as UserRoles from 'config/UserRoles'
import _ from 'lodash'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

export default function UserLists({ user, handleDelelte, handleUpdate }) {
  const { t } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const station = useSelector((state) => state.station)
  const [selectedStation, setSelectedStation] = useState([])
  const [rerender, setRerender] = useState(false)

  const setSelect = (index, user) => {
    console.log('index = ', index)
    // ดูว่าใน Select Station มี Station ID ตัวนี้มั้ย

    setSelectedStation(user)
    onOpen()
    setRerender(!rerender)
  }

  const handleCloseModal = () => {
    setSelectedStation([])
    onClose()
  }

  const handleSuccess = () => {
    handleUpdate(selectedStation?._id, { duty: selectedStation?.duty })
    handleCloseModal()
  }

  const renderStationSelect = (userId) => {
    return <div>{showDuty()}</div>
  }

  const showDuty = () => {
    return _.map(station?.arr, (_station, index) => (
      <div
        className={`${
          _station?._id === selectedStation?.duty?.[index]
            ? 'bg-blue-400'
            : 'bg-gray-200'
        } p-2 px-4 my-2 rounded-md cursor-pointer`}
        onClick={() => {
          if (selectedStation.duty[index] === _station?._id) {
            // ลบ station ตัวนี้ออกจาก Array
            selectedStation.duty[index] = null
          } else {
            // เพิ่มลงใน Array
            selectedStation.duty[index] = _station?._id
          }
          setSelectedStation(selectedStation)
          setRerender(!rerender)
        }}
      >
        {_station?.name}
      </div>
    ))
  }

  const genUserLists = () =>
    _.map(user, (_user, index) => (
      <Tr key={_user._id}>
        <Td>{index + 1}</Td>
        <Td>{_user.name}</Td>
        <Td>{_user.email}</Td>
        <Td>{_user.tel}</Td>
        <Td>
          <select
            onChange={(e) => {
              const data = { role: e.target.value }
              const confirm = window.confirm(
                'ยืนยันเปลี่ยนบทบาทบัญชีผู้ใช้จาก' +
                  _user.role +
                  'เป็น' +
                  e.target.value
              )
              if (confirm) handleUpdate(_user.id, data)
            }}
            defaultValue={_user.role}
            className="rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          >
            {genUserRolesOptions()}
          </select>
        </Td>
        <Td>
          <div className="flex justify-items-center">
            <Button
              variant="solid"
              size="sm"
              colorScheme="yellow"
              onClick={() => setSelect(index, _user)}
            >
              {' '}
              แก้ไขสถานีสถานีที่ต้องการ{' '}
            </Button>

            <Modal isOpen={isOpen} onClose={handleCloseModal} size={'md'}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>
                  <div class="w-full grid justify-items-center">
                    เลือกสถานีที่ต้องการดูแล
                  </div>
                </ModalHeader>
                <ModalBody>
                  <CheckboxGroup colorScheme="blue">
                    <Stack spacing={2} direction={'column'}>
                      {renderStationSelect(_user._id)}
                    </Stack>
                  </CheckboxGroup>
                </ModalBody>
                <ModalFooter>
                  <Button
                    colorScheme="red"
                    mr={3}
                    size="sm"
                    variant="solid"
                    onClick={handleCloseModal}
                  >
                    {t('utility.cancel')}
                  </Button>
                  <Button
                    colorScheme="green"
                    size="sm"
                    onClick={() => handleSuccess()}
                    type="button"
                  >
                    {t('utility.save')}
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </div>
        </Td>
        <Td>
          <Button
            colorScheme="red"
            size="sm"
            onClick={() => {
              if (_user.role == UserRoles.ROLES.SUPER_USER) {
                alert('ไม่สามารถลบบัญชี SUPERUSER')
              } else {
                const confirm = window.confirm('ยืนยันการลบบัญชีผู้ใช้')
                if (confirm) handleDelelte(_user.id)
              }
            }}
          >
            {t('utility.delete')}
          </Button>
        </Td>
      </Tr>
    ))

  const genStationList = () => {
    return _.map(station.arr, (eachStation, index) => (
      <option key={index} value={eachStation._id}>
        {eachStation.name}{' '}
      </option>
    ))
  }

  const genUserRolesOptions = () => (
    <>
      <option value={UserRoles.ROLES.USER}>{UserRoles.ROLES.USER}</option>
      <option value={UserRoles.ROLES.GENERAL}>{UserRoles.ROLES.GENERAL}</option>
      <option value={UserRoles.ROLES.ADMIN}>{UserRoles.ROLES.ADMIN}</option>
      <option value={UserRoles.ROLES.SUPER_USER}>
        {UserRoles.ROLES.SUPER_USER}
      </option>
    </>
  )
  return (
    <div>
      <Table size="sm" variant="striped" className="whitespace-no-wrap">
        <Thead>
          <Tr>
            <Th>#</Th>
            <Th> {t('utility.name')}</Th>
            <Th>Email</Th>
            <Th> {t('utility.tel')}</Th>
            <Th> {t('utility.role')}</Th>
            <Th> {t('system.stationAttendant')}</Th>
            <Th>
              {' '}
              <Th> {t('utility.action')}</Th>
            </Th>
          </Tr>
        </Thead>
        <Tbody>{genUserLists()}</Tbody>
      </Table>
    </div>
  )
}
