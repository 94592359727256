import axios from 'axios'
import { SENSOR_ALL, SENSOR_GET, SENSOR_PUT, SENSOR_DEL } from '../types'
import * as actions from '../../actions'

export const sensorAll = () => {
  return async (dispatch) => {
    await axios.get(process.env.REACT_APP_API_URL + '/sensor').then((res) => {
      console.log('Request Server to Get All Sensors')
      dispatch({ type: SENSOR_ALL, payload: res.data })
    })
  }
}

export const sensorRealtimeGet = (payload) => {
  return async (dispatch) => {
    const { stationId = '', sensorId = '' } = payload
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/sensor/realtime?stationId=${stationId}&sensorId=${sensorId}`
      )
      .then((res) => {
        console.log('Request Server to Get an Sensors')
        if (res.data) {
          dispatch({ type: SENSOR_GET, payload: res.data })
        } else {
          dispatch({ type: SENSOR_GET, payload: null })
        }
      })
  }
}

export const sensorGet = (payload) => {
  return async (dispatch) => {
    await axios
      .get(process.env.REACT_APP_API_URL + '/sensor/' + payload)
      .then((res) => {
        console.log('Request Server to Get an Sensors')
        if (res.data) {
          dispatch({ type: SENSOR_GET, payload: res.data })
        } else {
          dispatch({ type: SENSOR_GET, payload: null })
        }
      })
  }
}
// สั่ง TimerON
export const timerControl = (stationRef, controlRef, payload) => {
  return async (dispatch) => {
    await axios
      .post(
        process.env.REACT_APP_API_URL +
          '/sensor/control/timer/' +
          stationRef +
          '/' +
          controlRef,
        payload
      )
      .then(async (res) => {
        console.log('Request Timer ON')

        // await axios.get(
        //   process.env.REACT_APP_API_URL + '/station/' + stationRef
        // )
        if (res.status === 200) {
          dispatch(actions.stationAll())
        }
      })
  }
}

// ตั้งค่า threshold
export const thresholdControl = (stationRef, controlRef, payload) => {
  return async (dispatch) => {
    await axios
      .post(
        process.env.REACT_APP_API_URL +
          '/sensor/control/threshold/' +
          stationRef +
          '/' +
          controlRef,
        payload
      )
      .then(async (res) => {
        console.log('Request Timer ON')

        // await axios.get(
        //   process.env.REACT_APP_API_URL + '/station/' + stationRef
        // )
        if (res.status === 200) {
          dispatch(actions.stationAll())
        }
      })
  }
}

export const turnSensorOn = (stationRef, controlRef) => {
  return async (dispatch) => {
    await axios
      .post(
        process.env.REACT_APP_API_URL +
          '/sensor/control/on/' +
          stationRef +
          '/' +
          controlRef
      )
      .then(async (res) => {
        console.log('Request Turn on Sensor')
        // await axios.get(
        //   process.env.REACT_APP_API_URL + '/station/' + stationRef
        // )
        if (res.status === 200) {
          dispatch(actions.stationAll())
        }
      })
  }
}

export const turnSensorOff = (stationRef, controlRef) => {
  return async (dispatch) => {
    await axios
      .post(
        process.env.REACT_APP_API_URL +
          '/sensor/control/off/' +
          stationRef +
          '/' +
          controlRef
      )
      .then(async (res) => {
        // await axios.get(
        //   process.env.REACT_APP_API_URL + '/station/' + stationRef
        // )
        console.log('Request Turn on Sensor')
        if (res.status === 200) {
          dispatch(actions.stationAll())
        }
      })
  }
}

export const toggleSensorWithPulse = (stationRef, controlRef) => {
  return async (dispatch) => {
    await axios
      .post(
        process.env.REACT_APP_API_URL +
          '/sensor/pulse/toggle/' +
          stationRef +
          '/' +
          controlRef
      )
      .then(async (res) => {
        console.log('Request Toggle Sensor Value')
        // await axios.get(
        //   process.env.REACT_APP_API_URL + '/station/' + stationRef
        // )
        if (res.status === 200) {
          dispatch(actions.stationAll())
        }
      })
  }
}

export const turnMajorSensorOff = (stationRef, controlRef) => {
  return async (dispatch) => {
    await axios
      .post(
        process.env.REACT_APP_API_URL +
          '/sensor/controlEnable/off/' +
          stationRef +
          '/' +
          controlRef
      )
      .then(async (res) => {
        // await axios.get(
        //   process.env.REACT_APP_API_URL + '/station/' + stationRef
        // )
        console.log('Request Turn off Major Sensor')
        if (res.status === 200) {
          dispatch(actions.stationAll())
        }
      })
  }
}
export const turnMajorSensorOn = (stationRef, controlRef) => {
  return async (dispatch) => {
    await axios
      .post(
        process.env.REACT_APP_API_URL +
          '/sensor/control/on/' +
          stationRef +
          '/' +
          controlRef
      )
      .then(async (res) => {
        console.log('Request Turn on Sensor')
        // await axios.get(
        //   process.env.REACT_APP_API_URL + '/station/' + stationRef
        // )
        if (res.status === 200) {
          dispatch(actions.stationAll())
        }
      })
  }
}

export const sensorPut = (id, payload) => {
  return async (dispatch) => {
    await axios
      .put(process.env.REACT_APP_API_URL + '/sensor/' + id, payload)
      .then((res) => {
        console.log('Request Server to put an Sensors')
        dispatch({ type: SENSOR_PUT, payload: null })
      })
  }
}

export const sensorDelete = (payload) => {
  return async (dispatch) => {
    await axios
      .delete(process.env.REACT_APP_API_URL + '/sensor/' + payload)
      .then((res) => {
        console.log('Request Server to Delete an Sensors')
        dispatch({ type: SENSOR_DEL, payload: null })
      })
  }
}
