import React from 'react'
import _ from 'lodash'

import Config from '../../../../config'
import VariableMainRendering from '../../../Variables/Function/VariableMainRendering'

export default function DisplayBooleanAttribute({
  stationData,
  attributeData,
}) {
  if (attributeData.dataSource === Config.TypeOfSource.GaugeSource.sensor) {
    const sensorSourceData = _.find(
      stationData?.sensors,
      (sensor) => sensor.refString === attributeData.sensorRef
    )

    if (sensorSourceData) {
      if (attributeData.isActiveHigh === true) {
        return (
          <div className=" text-sm">
            <div className="flex flex-col">
              {sensorSourceData.booleanStatus === true ? (
                <>
                  {/* <div className="rounded-full circle bg-green-600 w-2 my-2 h-1/2 "></div> */}
                  <div>
                    {' '}
                    {attributeData.label}
                    {':'} {attributeData.labelActive}{' '}
                  </div>
                </>
              ) : (
                <>
                  {/* <div className="rounded-full circle bg-red-600 w-2 my-2 h-3/4"></div> */}
                  <div>
                    {' '}
                    {attributeData.label}
                    {':'} {attributeData.labelInactive}{' '}
                  </div>
                </>
              )}
            </div>
          </div>
        )
      } else {
        return (
          <div className=" text-sm">
            <div className="flex flex-col">
              {sensorSourceData.booleanStatus === false ? (
                <>
                  {/* <div className="rounded-full circle bg-green-600 w-2 my-2 h-1/2 "></div> */}
                  <div>
                    {attributeData.label}
                    {':'} {attributeData.labelActive}{' '}
                  </div>
                </>
              ) : (
                <>
                  {/* <div className="rounded-full circle bg-red-600 w-2 my-2 h-1/2 "></div> */}
                  <div>
                    {attributeData.label}
                    {':'} {attributeData.labelInactive}{' '}
                  </div>
                </>
              )}
            </div>
          </div>
        )
      }
    } else {
      return <></>
    }
  } else {
    const variableData = VariableMainRendering(
      stationData,
      attributeData.variableId
    )
    if (attributeData.isActiveHigh === true) {
      return (
        <div className=" text-sm">
          <div className="flex flex-col">
            {variableData === true ? (
              <>
                {/* <div className="rounded-full circle bg-green-600 w-2 my-2 h-1/2 "></div> */}
                <div>
                  {attributeData.label}
                  {':'} {attributeData.labelActive}{' '}
                </div>
              </>
            ) : (
              <>
                {/* <div className="rounded-full circle bg-red-600 w-2 my-2 h-1/2 "></div> */}
                <div>
                  {attributeData.label}
                  {':'} {attributeData.labelInactive}{' '}
                </div>
              </>
            )}
          </div>
        </div>
      )
    } else {
      return (
        <div className=" text-sm">
          {' '}
          <div className="flex flex-col">
            {variableData === false ? (
              <>
                {/* <div className="rounded-full circle bg-green-600 w-2 my-2 h-1/2 "></div> */}
                <div>
                  {attributeData.label}
                  {':'} {attributeData.labelActive}{' '}
                </div>
              </>
            ) : (
              <>
                {/* <div className="rounded-full circle bg-red-600 w-2 my-2 h-1/2 "></div> */}
                <div>
                  {attributeData.label}
                  {':'} {attributeData.labelInactive}{' '}
                </div>
              </>
            )}
          </div>
        </div>
      )
    }
  }
}
