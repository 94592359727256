import React, { useState } from 'react'
import Chart from 'react-apexcharts'
import { Box } from '@chakra-ui/react'

export default function DisplayMeterColorsAttribute({
  staticData,
  graphLabel,
  unit,
  highResolution,
  lowResolution,
  valueLable,
}) {
  const series = [(staticData * 100) / (highResolution - lowResolution)]
  const colorsRange = {
    yellowRange: lowResolution + (highResolution - lowResolution) * 0.8,
    redRange: lowResolution + (highResolution - lowResolution) * 0.9,
  }
  const options = {
    plotOptions: {
      radialBar: {
        startAngle: -120,
        endAngle: 120,
        track: {
          background: '#e7e7e7',
          strokeWidth: '97%',
          margin: 5, // margin is in pixels
        },
        dataLabels: {
          name: {
            show: true,
          },
          value: {
            show: true,
            formatter: (val) => parseFloat(staticData).toFixed(2) + ' ' + unit,
            offsetY: 5,
          },
        },
      },
    },
    colors: [
      (staticData * 100) / (highResolution - lowResolution) >
      colorsRange.redRange
        ? '#8DEEA6'
        : (staticData * 100) / (highResolution - lowResolution) >
          colorsRange.yellowRange
        ? '#90D4E7'
        : '#90D4E7',
    ],

    // stroke: {
    //   dashArray: 4,
    // },
    labels: [valueLable ? valueLable : ''],
  }

  return (
    <Box
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      className=" ml-4 my-2 p-4 w-full  h-64"
    >
      <Chart height="270" series={series} options={options} type="radialBar" />
    </Box>
  )
}
