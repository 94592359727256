import React, { useEffect, useState } from 'react'
import { Box } from '@chakra-ui/react'
import _ from 'lodash'
import { rationalize, evaluate } from 'mathjs'

function MathCalculateFormComponent({ formData, watch, register, setValue }) {
  const [result, setResult] = useState()
  const [calculateAgain, setCalculateAgain] = useState(false)

  const variable = rationalize(formData?.calculate, {}, true).variables

  const calcuateResult = () => {
    const scope = {}
    _.map(formData?.attribute, (each, index) => {
      scope[variable[index]] = parseFloat(watch(each?.subTopic)) || 0
    })

    try {
      const tempResult = evaluate(formData?.calculate, scope)
      setResult(tempResult)

      //console.log('Result', tempResult)
    } catch (error) {
      console.log('ERROR Call', error)
      setResult(0)
    }
  }

  useEffect(() => {
    let timeout
    if (calculateAgain) {
      calcuateResult()
      setCalculateAgain(false)
    } else {
      timeout = setTimeout(() => {
        console.log('Re Calculate')
        setCalculateAgain(true)
      }, 2000)
    }
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [calculateAgain])

  useEffect(() => {
    calcuateResult()

    return () => {}
  }, [watch(formData), watch(result)])

  const test = {}

  _.map(formData?.attribute, (each, index) => {
    test[variable[index]] = parseFloat(watch(each?.subTopic)) || 0
  })
  return (
    <div>
      <h2>
        <Box
          maxW="sm"
          borderWidth="1px"
          borderRadius="lg"
          className=" ml-4 my-2 p-4 w-auto h-32"
        >
          <label className="block uppercase text-gray-700 text-md font-bold mb-2">
            {formData.topic}
            {formData?.required && <span className="text-red-500"> *</span>}
          </label>{' '}
          <code>{formData.calculate}</code>
          <input
            name={formData.name}
            value={isNaN(result) ? 0 : result}
            ref={register}
            readOnly
            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-md shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          />
        </Box>
      </h2>
    </div>
  )
}

export default MathCalculateFormComponent
