/* eslint-disable quotes */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import _ from 'lodash'
import { Button, Box, Select, Switch } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import SensorRequireAttribute from 'views/system/management-statations/Components/SensorRequireAttribute'

// Use for checkbox
function EditMultiinputForm({
  fields,
  append,
  remove,
  register,
  control,
  componentData,
  Multiinput,
  inputSensor,
  setInputSensor,
}) {
  const { t, i18n } = useTranslation()
  const handleRemove = (index) => {
    remove(index)
  }

  return (
    <div>
      <div className="w-full px-4">
        <div className="w-full px-4">
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              ชื่อฟอร์ม
            </label>
            <Controller
              name="name"
              defaultValue={componentData?.name ? componentData?.name : ''}
              control={control}
              render={(field) => (
                <input
                  {...field}
                  type="text"
                  required
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="Meter"
                />
              )}
            />
          </div>
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              ชื่อหัวข้อใหญ่
            </label>
            <Controller
              name="topic"
              control={control}
              defaultValue={componentData?.topic ? componentData?.topic : ''}
              render={(field) => (
                <input
                  {...field}
                  type="text"
                  required
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="มิเตอร์"
                />
              )}
            />
          </div>
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              คำอธิบายหัวข้อใหญ่
            </label>
            <Controller
              name="description"
              defaultValue={
                componentData?.description ? componentData?.description : ''
              }
              control={control}
              render={(field) => (
                <input
                  {...field}
                  type="text"
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="ใช้สำหรับมิเตอร์"
                />
              )}
            />
          </div>
        </div>
      </div>
      {_.map(fields, (row, index) => (
        <Box
          key={row?.id}
          padding="4"
          borderRadius="lg"
          borderWidth="2"
          className="text-sm font-semibold border mb-2"
        >
          {console.log('row', row)}
          <div className="flex justify-between">
            <h5 className="mb-2">หัวข้อย่อยที่ {index + 1} </h5>
            <Button
              colorScheme="red"
              size="sm"
              onClick={() => handleRemove(index)}
            >
              {t('utility.delete')}
            </Button>
          </div>
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              ชื่อหัวข้อย่อย
            </label>
            <Controller
              name={`attributeArray[${index}].subTopic`}
              defaultValue={row?.subTopic ? row?.subTopic : ''}
              control={control}
              render={(field) => (
                <input
                  {...field}
                  type="text"
                  required
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="อุณหภูมิ"
                />
              )}
            />
            <Controller
              name={`attributeArray[${index}].inputSensor.sensorId`}
              defaultValue={row?.inputSensor?.sensorId}
              control={control}
              render={(field) => (
                <input
                  {...field}
                  hidden
                  type="text"
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="อุณหภูมิ"
                />
              )}
            />
            {/* <Controller
              name={`attributeArray[${index}].inputSensor.sensor`}
              defaultValue={row?.inputSensor?.sensor}
              control={control}
              type="boolean"
              render={(field) => <Switch checked={row?.inputSensor?.sensor} />}
            /> */}
          </div>
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              คำอธิบายหัวข้อย่อย
            </label>
            <Controller
              name={`attributeArray[${index}].subDesc`}
              control={control}
              defaultValue={row?.subDesc ? row?.subDesc : ''}
              render={(field) => (
                <input
                  {...field}
                  name={`attributeArray.${index}.subDesc`}
                  type="text"
                  className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                  placeholder="ใช้สำหรับระบุ..."
                />
              )}
            />
          </div>
          <div>
            <SensorRequireAttribute
              index={index}
              inputSensor={inputSensor}
              setInputSensor={setInputSensor}
            />
          </div>

          {Multiinput && (
            <div>
              <div className="relative w-full mb-3">
                <label>{t('utility.valueType')}</label>
                <Controller
                  name={`attributeArray.${index}.subInputType`}
                  defaultValue={row?.subInputType ? row?.subInputType : 'TEXT'}
                  control={control}
                  render={(field) => (
                    <Select
                      required
                      {...field}
                      // onChange={(event) => handleChange(event.target.value,)}
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    >
                      <option value="DATE">Date</option>
                      <option value="TIME">Time</option>
                      <option value="NUMBER">Number</option>
                      <option value="TEXT">Text</option>
                    </Select>
                  )}
                />
              </div>
              <div className="relative w-full mb-3 py-3 ">
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Required
                </label>
                <Controller
                  name={`attributeArray.${index}.required`}
                  defaultValue={row?.required ? row?.required : true}
                  control={control}
                  render={(field) => (
                    <Select
                      required
                      {...field}
                      // onChange={(event) => handleChange(event.target.value,)}
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                    >
                      <option value={true}>true</option>
                      <option value={false}>false</option>
                    </Select>
                  )}
                />
              </div>
            </div>
          )}
        </Box>
      ))}
    </div>
  )
}

export default EditMultiinputForm
