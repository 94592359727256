// HARD CODE
import React from 'react'
import LiquidGauge from 'react-liquid-gauge'
import { Box } from '@chakra-ui/react'
import _ from 'lodash'
export default function MultiStepBooleanWaterDisplay({
  lowerSensorData,
  upperSensorData,
  graphLabel,
  isUpperActiveHigh = true,
  isLowerActiveHigh = true,
  highLabel,
  lowLabel,
  swingLabel,
}) {
  const loweringBoolean = lowerSensorData
    ? lowerSensorData.booleanStatus
    : false
  const upperBoolean = upperSensorData ? upperSensorData.booleanStatus : false

  let liquildLevel = 0

  // Normal Floating Ball Logic
  if (isUpperActiveHigh === true && isLowerActiveHigh === true) {
    if (loweringBoolean === false) {
      liquildLevel = 10
    } else if (loweringBoolean === true && upperBoolean === false) {
      liquildLevel = 50
    } else if (loweringBoolean === true && upperBoolean === true) {
      liquildLevel = 90
    }
  }
  // High Level Reverse Logic
  else if (isUpperActiveHigh === false && isLowerActiveHigh === true) {
    if (loweringBoolean === false) {
      liquildLevel = 10
    } else if (loweringBoolean === true && upperBoolean === false) {
      liquildLevel = 90
    } else if (loweringBoolean === true && upperBoolean === true) {
      liquildLevel = 50
    }
  }
  // Low Level Reverse Logic
  else if (isUpperActiveHigh === true && isLowerActiveHigh === false) {
    if (loweringBoolean === true) {
      liquildLevel = 10
    } else if (loweringBoolean === false && upperBoolean === false) {
      liquildLevel = 50
    } else if (loweringBoolean === false && upperBoolean === true) {
      liquildLevel = 90
    }
  }
  // All reverse Logic
  else if (isUpperActiveHigh === false && isLowerActiveHigh === false) {
    if (loweringBoolean === true) {
      liquildLevel = 10
    } else if (loweringBoolean === false && upperBoolean === false) {
      liquildLevel = 90
    } else if (loweringBoolean === false && upperBoolean === true) {
      liquildLevel = 50
    }
  }

  return (
    <Box
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      className=" ml-4 my-2 p-4 w-full h-64"
    >
      <h5 className="font-bold text-2xl"> {graphLabel} </h5>
      <div className="flex justify-center mt-4 pt-2">
        <LiquidGauge
          value={liquildLevel}
          textRenderer={(val) => {
            if (liquildLevel < 15) return lowLabel
            else if (liquildLevel > 85) return highLabel
            else return swingLabel
          }}
          riseAnimation
          waveAnimation
          waveFrequency={2}
          waveAmplitude={1}
          gradient
          width={120}
          height={120}
          circleStyle={{ fill: 'lightgrey' }}
        />
      </div>
    </Box>
  )
}
