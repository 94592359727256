import React, { useState } from 'react'
import {
  Badge,
  Box,
  Button,
  Code,
  FormControl,
  Input,
  Select,
  Checkbox,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import 'katex/dist/katex.min.css'
import axios from 'axios'
import Latex from 'react-latex'

import * as actions from '../../redux/actions'
import config from '../../config'

export default function EquationComponent({
  equationData,
  stationData,
  placeToEdit = 'station',
}) {
  const { t } = useTranslation()
  const [isEditorMode, setIsEditorMode] = useState(false)
  const { register, handleSubmit, watch } = useForm()
  const dispatch = useDispatch()
  const [isShowEquation, setIsShowEquation] = useState('')
  const [isEquation, setIsEquation] = useState('')
  const [isSymbol, setIsSymbol] = useState([])

  // Merged the Equation Data and Make new Station Data to Database
  const prepareUpdateDatabase = (data) => {
    data.latex = isShowEquation
    data.symbol = isSymbol
    const selectedEquation = _.find(
      stationData.equations,
      (equation) => equation._id === equationData._id
    )
    const selectedEquationIndex = _.findIndex(
      stationData.equations,
      (equation) => equation._id === equationData._id
    )
    const mergedequationData = _.merge(selectedEquation, data)
    let editedStation = stationData
    editedStation.equations[selectedEquationIndex] = mergedequationData

    // is on Template or on Station FIXME: Magic Code

    if (placeToEdit === 'template') {
      console.log('This is Template not station')
      dispatch(actions.stationTemplatePut(stationData._id, editedStation))
    } else {
      console.log('This is Station')
      dispatch(actions.stationPut(stationData._id, editedStation)).then(() => {
        dispatch(actions.stationAll())
      })
    }

    setIsEditorMode(false)
    window.location.reload()
  }

  const prepateDeleteEquation = (equationId) => {
    let confirm = window.confirm('ยืนยันการลบสมการ')
    if (confirm) {
      const selectedEquationIndex = _.findIndex(
        stationData.equations,
        (equation) => equation._id === equationId
      )

      let editedStation = stationData
      if (editedStation.equations) {
        console.log('Original Equation Stack', editedStation.equations)
        editedStation.equations.splice(selectedEquationIndex, 1)
        console.log('SPLICED')
        console.log('New Equation Stack', editedStation.equations)
      }

      // is on Template or on Station FIXME: Magic Code
      if (placeToEdit === 'template') {
        console.log('This is Template not station')
        dispatch(actions.stationTemplatePut(stationData._id, editedStation))
      } else {
        console.log('This is Station')
        dispatch(actions.stationPut(stationData._id, editedStation)).then(
          () => {
            dispatch(actions.stationAll())
          }
        )
      }
      setIsEditorMode(false)
    }
  }

  const renderEquation = () => {
    axios
      .post(process.env.REACT_APP_API_URL + '/equation', {
        equation: isEquation,
      })
      .then(async (res) => {
        const payload = res.data
        console.log('Equation', payload)
        setIsShowEquation(payload.latex)
        setIsSymbol(payload.symbol)
      }) 
  }

  const editorModeComponent = () => (
    <div>
      <FormControl>
        <label className="text-sm font-bold"> ชื่อสมการ </label>
        <Input
          type="text"
          name="name"
          size="sm"
          defaultValue={equationData.name}
          ref={register}
          placeholder={t('เช่น สมการคำนวณพื้นที่วงกลม ')}
        />
      </FormControl>

      <FormControl>
        <label className="text-sm font-bold"> เกี่ยวกับสมการ </label>
        <Input
          type="text"
          name="description"
          size="sm"
          defaultValue={equationData.description}
          ref={register}
          placeholder={'เช่น ใช้คำนวณหาพื้นที่วงกลม'}
        />
      </FormControl>

      <FormControl>
        <label className="text-sm font-bold">ใส่สมการ</label>
        <Input
          type="text"
          name="equationString"
          size="sm"
          defaultValue={equationData.equationString}
          ref={register}
          placeholder="pi*(r**2)"
          onChange={(e) => setIsEquation(e.target.value)}
        />{' '}
      </FormControl>

      <Button colorScheme="blue" variant="solid" onClick={renderEquation}>
        {'แสดงสมการ'}{' '}
      </Button>

      <div className="relative w-full  mb-3">
        <label className="text-sm font-bold">แสดงสมการ</label>
        <div className="flex justify-center mx-2 my-2 text-lg font-bold mb-2 bg-gray-100 shadow-md rounded-md">
          <Latex displayMode={true}
          >
            {'$$ f(x) = ' + isShowEquation + '$$'}
          </Latex>
        </div>
      </div>
    </div>
  )

  const displayModeComponent = () => (
    <div className="flex flex-col justify-start">
      <p className="text-sm "> {equationData.description} </p>
      <Latex displayMode={true}>
        {'$$ f(x) = ' + equationData.latex + '$$'}
      </Latex>
    </div>
  )

  return (
    <Box borderRadius="lg" padding="4" className="bg-white my-2">
      <form onSubmit={handleSubmit(prepareUpdateDatabase)}>
        <div className="flex gap-3">
          <div className="w-5/6">
            <h5 className="text-lg font-bold font-sans">
              {' '}
              {equationData.name}{' '}
            </h5>
          </div>

          <div className="flex gap-1 ">
            {isEditorMode === true ? (
              <>
                <Button
                  size="sm"
                  type="button"
                  colorScheme="gray"
                  onClick={() => setIsEditorMode(false)}
                >
                  {t('utility.cancel')}
                </Button>{' '}
                <Button size="sm" colorScheme="green" type="submit">
                  {t('utility.save')}
                </Button>
              </>
            ) : (
              <>
                <Button
                  size="sm"
                  colorScheme="yellow"
                  onClick={() => setIsEditorMode(true)}
                  type="button"
                >
                  {t('utility.edit')}
                </Button>
                <Button
                  size="sm"
                  colorScheme="red"
                  type="button"
                  onClick={() => prepateDeleteEquation(equationData._id)}
                >
                  {t('utility.delete')}
                </Button>
              </>
            )}
          </div>
        </div>

        <div className="flex ">
          <div>
            {isEditorMode === true
              ? editorModeComponent()
              : displayModeComponent()}
          </div>
        </div>
      </form>
    </Box>
  )
}
