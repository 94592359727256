import React, { useState } from 'react'
import {
  Badge,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import * as actions from '../../../redux/actions'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import hash from 'object-hash'
import { useTranslation } from 'react-i18next'

import CreateComponentDiagram from './CreateComponentDiagram'
import DiagramRenderingComponent from '../Function/DiagramRenderingComponent'
import config from '../../../config'

const plainAttribute = {
  label: 'String',
  preferDataType: 'String',
  isActiveHigh: true,
  labelActive: '',
  labelInactive: '',
  unit: 'String',
  isShowLabel: true,
  showLightStatus: true,
  dataSource: 'Sensor',
  sensorRef: '',
  variableId: '',
}

export default function DiagramMakingComponent({ stationData, diagramData }) {
  const { t } = useTranslation()
  const [isEditorMode, setIsEditorMode] = useState(false)
  const [isRequestEditImage, setIsRequestEditImage] = useState(false)
  const [selectedDiagram, setSelectedDiagram] = useState()
  const [rendered, setRendered] = useState(false)
  const [selectImage, setSelectImage] = useState({})
  const [typeImage, setTypeImage] = useState('')

  const { register, handleSubmit } = useForm()
  const dispatch = useDispatch()

  const genDiagramList = () => {
    return _.map(
      config?.ComponentType?.DiagramType,
      (eachDiagramType, diagramTypeIndex) => (
        <option key={diagramTypeIndex} value={eachDiagramType?.value}>
          {' '}
          {eachDiagramType?.describe}{' '}
        </option>
      )
    )
  }

  // Merged the Sensor Data and Make new Station Data to Database
  const prepareUpdateDatabase = async (data) => {
    // Image Uploading

    const fileName = hash({
      name: selectImage.name,
      date: new Date(),
    })

    data.diagramImage = selectImage.value
    data.diagramTypeImage = typeImage

    const selectedDiagram = _.find(
      stationData.diagrams,
      (diagram) => diagram._id === diagramData._id
    )

    const selectedDiagramIndex = _.findIndex(
      stationData.diagrams,
      (diagram) => diagram._id === diagramData._id
    )
    const mergeddiagramData = _.merge(selectedDiagram, data)
    const editedStation = stationData
    editedStation.diagrams[selectedDiagramIndex] = mergeddiagramData
    // console.log('This is Station', editedStation)
    // console.log('This is Station', stationData._id)
    // console.log(data)
    dispatch(actions.stationPut(stationData._id, editedStation)).then(() => {
      dispatch(actions.stationAll())
    })
    setIsEditorMode(false)
  }

  const prepateDeleteSensor = (diagramId) => {
    const confirm = window.confirm('ยืนยันการลบไดอะแกรม ')
    if (confirm) {
      const selectedSensorIndex = _.findIndex(
        stationData.diagrams,
        (diagram) => diagram._id === diagramId
      )
      const editedStation = stationData
      if (editedStation.diagrams) {
        console.log('Original Sensor Stack', editedStation.diagrams)
        editedStation.diagrams.splice(selectedSensorIndex, 1)
        console.log('SPLICED')
        console.log('New Sensor Stack', editedStation.diagrams)
      }
      console.log('This is Station')
      dispatch(actions.stationPut(stationData._id, editedStation)).then(() => {
        dispatch(actions.stationAll())
      })
      setIsEditorMode(false)
    }
  }

  const addNewAttributeToDiagram = (newList) => {
    if (newList) {
      const currentAttribute = diagramData.attribute
      try {
        currentAttribute.push(plainAttribute)
        diagramData.attribute = currentAttribute
        setRendered(!rendered)
      } catch (error) {
        console.error('Cannot add new Attribute')
        console.error(error)
      }
    }
  }

  const removeAttributeToDiagram = (attrIndex) => {
    const currentAttribute = diagramData.attribute
    try {
      currentAttribute.splice(attrIndex, 1)
      diagramData.attribute = currentAttribute
      setRendered(!rendered)
    } catch (error) {
      console.error('Cannot remove Attribute')
      console.error(error)
    }
  }

  return (
    <Box borderRadius="lg" padding="4" className="bg-white my-2">
      {' '}
      <form onSubmit={handleSubmit(prepareUpdateDatabase)}>
        <div className="flex gap-3">
          <div className="w-5/6">
            <h5 className="text-lg font-bold font-sans">
              {' '}
              {diagramData.diagramName}{' '}
            </h5>
          </div>
          <div className="flex gap-1 ">
            {isEditorMode === true ? (
              <>
                <Button
                  size="sm"
                  type="button"
                  colorScheme="gray"
                  onClick={() => setIsEditorMode(false)}
                >
                  {t('utility.cancel')}
                </Button>
                <Button size="sm" colorScheme="green" type="submit">
                  {t('utility.save')}
                </Button>
              </>
            ) : (
              <>
                <Button
                  size="sm"
                  colorScheme="yellow"
                  onClick={() => setIsEditorMode(true)}
                  type="button"
                >
                  {t('utility.edit')}
                </Button>
                <Button
                  size="sm"
                  colorScheme="red"
                  type="button"
                  onClick={() => prepateDeleteSensor(diagramData._id)}
                >
                  {t('utility.delete')}
                </Button>
              </>
            )}
          </div>
        </div>
        {/* 
              // Part of Display type of scada.
              */}
        <div className="flex gap-3">
          <div className="w-full lg:w-1/4 font-sans mx-4">
            <DiagramRenderingComponent
              attributeData={diagramData?.attribute[0]}
              stationData={stationData}
              diagramData={diagramData}
            />
          </div>
          <div className="w-full lg:w-3/4 text-sm">
            {isEditorMode === true ? (
              <>
                <FormControl>
                  <label className="font-semibold">
                    {t('sensor.diagramName')}
                  </label>
                  <Input
                    type="text"
                    name="diagramName"
                    ref={register}
                    defaultValue={diagramData.diagramName}
                    size="sm"
                  />
                </FormControl>
                <FormControl className="my-2">
                  <label className="font-semibold">รูปแบบของไดอะแกรม</label>
                  <Select
                    name="diagramType"
                    ref={register}
                    defaultValue={diagramData.diagramType}
                    onChange={(event) => setSelectedDiagram(event.target.value)}
                    size="sm"
                  >
                    {genDiagramList()}
                  </Select>
                </FormControl>
                {diagramData.attribute &&
                  _.map(diagramData.attribute, (attr, index) => (
                    <CreateComponentDiagram
                      diagramData={diagramData}
                      SelectDiagram={selectedDiagram}
                      selectImage={selectImage}
                      setSelectImage={setSelectImage}
                      key={index}
                      index={index}
                      attr={attr}
                      register={register}
                      stationData={stationData}
                      onDeleteAttribute={removeAttributeToDiagram}
                      newList={addNewAttributeToDiagram}
                      setTypeImage={setTypeImage}
                    />
                  ))}
              </>
            ) : (
              <div>
                <p className="text-sm ">
                  {' '}
                  <span className="text-sm font-semibold text-gray-700">
                    {t('sensor.diagramName')}
                  </span>{' '}
                  {diagramData.diagramName}{' '}
                </p>
                {diagramData.diagramType === 'Image' ? (
                  _.map(diagramData.attribute, (attr, index) => (
                    <ul key={index}>
                      <li className="text-sm ">
                        <ul>
                          <li>
                            <span className="font-semibold text-blue-700">
                              {index + 1}. {attr.sensorRef}
                            </span>{' '}
                            <Badge>{diagramData.diagramType}</Badge>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  ))
                ) : (
                  <div>
                    {diagramData.diagramType === 'Text' ? (
                      _.map(diagramData.attribute, (attr, index) => (
                        <ul key={index}>
                          <li className="text-sm ">
                            <ul>
                              <li>
                                <span className="font-semibold text-blue-700">
                                  {index + 1}. {attr.label}
                                </span>{' '}
                                <Badge>{attr.preferDataType}</Badge>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      ))
                    ) : (
                      <div>
                        {diagramData.diagramType === 'String' ? (
                          _.map(diagramData.attribute, (attr, index) => (
                            <ul key={index}>
                              <li className="text-sm ">
                                <ul>
                                  <li>
                                    <span className="font-semibold text-blue-700">
                                      {index + 1}. {attr.label}
                                    </span>{' '}
                                    <Badge>{attr.preferDataType}</Badge>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          ))
                        ) : (
                          <div></div>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </form>
    </Box>
  )
}
