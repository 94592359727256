import React, { useState, useEffect } from 'react'
import {
    Badge,
    Button,
    Code,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    FormControl,
    Input,
    Select,
} from '@chakra-ui/react'
import _ from 'lodash'
import GenerateMathermaticSymbol from '../../../../../util/GenerateMathematicSymbol'
import OperationSensorAdding from '../BackwardCompatible/Components/OperationSensorAdding'

export default function EditMultiStepBooleanDisplay({
    gaugeData,
    register,
    stationData,
    setAdditionalInfo,
}) {
    const originalVariableSize = _.size(gaugeData.multiLevelVariables)
    console.log('Former Variable Size' + originalVariableSize)

    //State Setting
    const [rendered, setRendered] = useState(false)
    const [isEditorMode, setIsEditorMode] = useState(false)
    const [gaugeVariables, setGagueVariables] = useState(
        gaugeData.multiLevelVariables
    )
    const [operationList, setOperationList] = useState(
        gaugeData.multiLevelOperations
    )

    const handleDeleteOperator = (operatorIndex) => {
        let tempOperatorArray = operationList
        tempOperatorArray.splice(operatorIndex, 1)
        setOperationList(tempOperatorArray)
        setRendered(!rendered)
    }

    useEffect(() => {
        const tempAdditonalInfo = {
            multiLevelOperations: operationList,
        }
        setAdditionalInfo(tempAdditonalInfo)
        return () => {}
    }, [operationList])

    const genRefStringList = () => {
        return _.map(stationData.sensors, (sensor) => (
            <option value={sensor.refString}> {sensor.refString} </option>
        ))
    }

    const gaugeOperation = () => (
        <>
            <option value="ADD"> บวก</option>
            <option value="SUBTRACT"> ลบ</option>
            <option value="MULTIPLY"> คูณ</option>
            <option value="DIVIDE"> หาร</option>
            <option value="NOP"> สิ้นสุดสมการ</option>
        </>
    )

    const colorList = () => (
        <>
            <option value="red">แดง</option>
            <option value="yellow">เหลือง</option>
            <option value="green">เขียว</option>
            <option value="blue">น้ำเงิน</option>
            <option value="purple">ม่วง</option>
            <option value="pink">ชมพู</option>
            <option value="gray">เทา</option>
        </>
    )

    return (
        <>
            <div>
                <div className="flex flex-wrap lg:flex-no-wrap gap-2 ">
                    <FormControl>
                        <label className="text-sm font-bold">
                            {' '}
                            คำที่จะแสดงเมื่อเป็นค่า High{' '}
                        </label>
                        <Input
                            type="text"
                            name="valueLabel.high"
                            size="sm"
                            defaultValue={gaugeData.valueLabel?.high}
                            ref={register}
                        />
                    </FormControl>
                    <FormControl>
                        <label className="text-sm font-bold">
                            ค่าที่จะแสดงเมื่อเป็นค่า Low{' '}
                        </label>
                        <Input
                            type="text"
                            name="valueLabel.low"
                            size="sm"
                            defaultValue={gaugeData.valueLabel?.low}
                            ref={register}
                        />
                    </FormControl>
                </div>
                <div className="flex flex-wrap lg:flex-no-wrap gap-2">
                    <FormControl>
                        <label className="text-sm font-bold">
                            {' '}
                            สีเมื่อแสดงค่าเป็น High{' '}
                        </label>
                        <Select
                            type="text"
                            name="valueColor.high"
                            size="sm"
                            defaultValue={gaugeData.valueColor?.high}
                            ref={register}
                        >
                            {colorList()}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <label className="text-sm font-bold">
                            {' '}
                            สีเมื่อแสดงค่าเป็น Low{' '}
                        </label>
                        <Select
                            type="text"
                            name="lowLevelColor"
                            size="sm"
                            defaultValue={gaugeData.valueColor?.low}
                            ref={register}
                        >
                            {colorList()}
                        </Select>
                    </FormControl>
                </div>
                <br />
                <label className="text-sm font-bold">การดำเนินการ</label>{' '}
                <Table variant="simple" size="sm">
                    <Thead>
                        <Tr>
                            <Th>ลำดับที่</Th>
                            <Th>เซ็นเซอร์</Th>
                            <Th>การดำเนินการ</Th>
                            <Th>เซ็นเซอร์</Th>
                            <Th></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {_.map(operationList, (eachOperation, index) => (
                            <Tr key={index}>
                                <Td>{index + 1} </Td>
                                <Td>
                                    {eachOperation.leftSensorRef === null ? (
                                        'ผลจากส่วนที่ ' + index
                                    ) : isEditorMode ? (
                                        <>
                                            <Select
                                                size="sm"
                                                defaultValue={
                                                    eachOperation.leftSensorRef
                                                }
                                            >
                                                {genRefStringList()}
                                            </Select>
                                        </>
                                    ) : (
                                        eachOperation.leftSensorRef
                                    )}
                                </Td>
                                <Td>
                                    {isEditorMode ? (
                                        <>
                                            <Select
                                                size="sm"
                                                defaultValue={
                                                    eachOperation.operation
                                                }
                                            >
                                                {gaugeOperation()}
                                            </Select>
                                        </>
                                    ) : (
                                        GenerateMathermaticSymbol(
                                            eachOperation.operation
                                        )
                                    )}
                                </Td>
                                <Td>
                                    {isEditorMode ? (
                                        <>
                                            <Select
                                                size="sm"
                                                defaultValue={
                                                    eachOperation.rightSensorRef
                                                }
                                            >
                                                {genRefStringList()}
                                            </Select>
                                        </>
                                    ) : (
                                        eachOperation.rightSensorRef
                                    )}
                                </Td>
                                <Td className="flex">
                                    {/* <Button
                    colorScheme="yellow"
                    type="button"
                    size="sm"
                    onClick={() => setIsEditorMode(!isEditorMode)}
                  >
                    แก้ไข
                  </Button>{' '} */}
                                    <Button
                                        colorScheme="red"
                                        type="button"
                                        size="sm"
                                        onClick={() =>
                                            handleDeleteOperator(index)
                                        }
                                    >
                                        ลบ
                                    </Button>{' '}
                                </Td>
                            </Tr>
                        ))}

                        <OperationSensorAdding
                            stationData={stationData}
                            gaugeVariables={gaugeVariables}
                            operationList={operationList}
                            setOperationList={setOperationList}
                            rendered={rendered}
                            setRendered={setRendered}
                        />
                    </Tbody>
                </Table>
            </div>
        </>
    )
}
