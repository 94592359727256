import { firebase, storage } from '../../config/firebase/firebase-client'

// delay เพื่อรอการค้นหารูปบน firebase
function delay(t, v) {
    return new Promise(function (resolve) {
        setTimeout(resolve.bind(null, v), t)
    })
}
// ให้วนหารูปภาพจนกว่าจะได้ 10 รอบ
function keepTrying(triesRemaining, storageRef) {
    if (triesRemaining < 0) {
        return Promise.reject('out of tries')
    }
    return storageRef
        .getDownloadURL()
        .then((url) => {
            return url
        })
        .catch((error) => {
            switch (error.code) {
                case 'storage/object-not-found':
                    return delay(2000).then(() => {
                        return keepTrying(triesRemaining - 1, storageRef)
                    })
                default:
                    console.log(error)
                    return Promise.reject(error)
            }
        })
}

// ชื่อไฟล์รูปภาพเเละรูปภาพ
const uploadImageFile = async (fileName, file) => {
    return new Promise((resolve, reject) => {
        const uploadTask = storage.ref('renita/' + `${fileName}`).put(file)

        uploadTask.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            function (snapshot) {
                var percent =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                console.log(percent + '% done')
            },
            function (error) {
                console.log(error)
                reject(error)
            },
            function (complete) {
                console.log('upload file complete!')
                //  const resizeFileName = fileName + '_' + 'resize'
                const storageRef = storage.ref(`renita`).child(fileName)
                keepTrying(10, storageRef).then((url) => {
                    console.log('get file resize file complete!')
                    resolve(url)
                })
            }
        )
    })
}

export default uploadImageFile
