import React from 'react'
import {
    Table,
    Thead,
    Tr,
    Th,
    Td,
    Tbody,
    Button,
    HStack,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import * as actions from '../../../../redux/actions'
import { Link } from 'react-router-dom'
import exportFromJSON from 'export-from-json'
export default function StationList({ stationArray }) {
    const dispatch = useDispatch()
    const handleDeleteStation = (stationId) => {
        let confirm = window.confirm('ยืนยันการลบสถานี')
        if (confirm) {
            dispatch(actions.stationDelete(stationId)).then(() => {
                dispatch(actions.stationAll())
            })
        }
    }

    const handleExportJSON = (eachStation) => {
        const file = exportFromJSON({
            data: eachStation,
            fileName: eachStation?.name,
            exportType: 'json',
        })
        console.log(file)
    }

    const genStationList = () => {
        return _.map(stationArray, (station, index) => (
            <Tr className="h-16 whitespace-no-wrap" key={index}>
                <Td>{index + 1}</Td>
                <Td>{station.name}</Td>
                <Td>{station.location}</Td>
                <Td> {station.templateName}</Td>
                {/* <Td> -</Td> */}
                <Td>
                    {' '}
                    <HStack spacing="2">
                        <Link to={'/system/dashboard/gauge/' + station._id}>
                            <Button colorScheme="blue" size="sm">
                                เกจที่ใช้แสดง
                            </Button>
                        </Link>
                        <Link to={'/system/dashboard/arrange/' + station._id}>
                            <Button colorScheme="yellow" size="sm">
                                การจัดเรียง
                            </Button>
                        </Link>
                        <Link to={'/system/dashboard/diagram/' + station._id}>
                            <Button colorScheme="blue" size="sm">
                                ไดอะแกรม
                            </Button>
                        </Link>
                        <Button
                            colorScheme="blue"
                            size="sm"
                            onClick={() => handleExportJSON(station)}
                        >
                            Export
                        </Button>
                    </HStack>
                </Td>
            </Tr>
        ))
    }
    return (
        <div>
            <Table size="sm" variant="striped">
                <Thead>
                    <Tr>
                        <Th>#</Th>
                        <Th>ชื่อสถานี</Th>
                        <Th>ที่ตั้ง</Th>
                        <Th> รูปแบบสถานี</Th>
                        {/* <Th> ผู้ดูแลสถานี</Th> */}
                        <Th> ดำเนินการ</Th>
                    </Tr>
                </Thead>
                <Tbody>{genStationList()}</Tbody>
            </Table>
        </div>
    )
}
