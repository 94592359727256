import React from 'react'
import { Box } from '@chakra-ui/react'
import _ from 'lodash'
export default function MultiStepNumberDisplay({
  stationData,
  unit,
  graphLabel,
  multiLevelVariables,
  multiLevelOperations,
}) {
  let opIndex = 0
  let lastestOperationResult = 0
  console.log('ขนาดของ Multilevel Operation ' + _.size(multiLevelOperations))
  //For All Operation
  for (opIndex = 0; opIndex < _.size(multiLevelOperations); opIndex++) {
    //Denote Value from Multi Level Operator
    const leftVariableName = multiLevelOperations[opIndex].leftVariable
    const rightVariableName = multiLevelOperations[opIndex].rightVariable
    const operation = multiLevelOperations[opIndex].operation

    // RIGHT WING

    // Find Right Variable (It must contain but left can be contain or ref to value after latest operation)
    const rightVariable = _.find(
      multiLevelVariables,
      (variable) => variable.variableName === rightVariableName
    )

    // Check if it from variable or constant
    let rightVariableNumberValue = 0

    if (rightVariable.useFromSensorNotVariable === true) {
      // As sensor
      const rightSensor = _.find(
        stationData.sensors,
        (sensor) => sensor.refString === rightVariable.sensorRef
      )
      if (rightSensor === undefined) {
      } else {
        rightVariableNumberValue = rightSensor.numericStatus
      }
    } else {
      rightVariableNumberValue = rightVariable.constantValue
    }

    // LEFT WING
    // Left Variable have a value
    if (leftVariableName !== null) {
      const leftVariable = _.find(
        multiLevelVariables,
        (variable) => variable.variableName === leftVariableName
      )

      let leftNumberStatus = 0

      if (leftVariable.useFromSensorNotVariable === true) {
        const leftSensor = _.find(
          stationData.sensors,
          (sensor) => sensor.refString === leftVariable.sensorRef
        )
        if (leftSensor === undefined) {
        } else {
          leftNumberStatus = leftSensor.numericStatus
        }
      } else {
        // As A constant
        leftNumberStatus = leftVariable.constantValue
      }

      // Update Data
      console.log('ทำการดำเนินการ')
      switch (operation) {
        case 'ADD':
          console.log('บวก')
          lastestOperationResult = leftNumberStatus + rightVariableNumberValue
          break
        case 'SUBTRACT':
          console.log('ลบ')
          lastestOperationResult = leftNumberStatus - rightVariableNumberValue
          break
        case 'MULTIPLY':
          console.log('คูณ')
          lastestOperationResult = leftNumberStatus * rightVariableNumberValue
          break
        case 'DIVIDE':
          console.log('หาร')
          lastestOperationResult = leftNumberStatus / rightVariableNumberValue
          break
        default:
          break
      }
    } else {
      // No Variable Addes (use latest value)
      console.log('ไม่มีการใส่ค่าตัวแปรด้านซ้าย ')
      console.log('กำลังคำนวณค่า')
      switch (operation) {
        case 'ADD':
          lastestOperationResult =
            lastestOperationResult + rightVariableNumberValue
          break
        case 'SUBTRACT':
          lastestOperationResult =
            lastestOperationResult - rightVariableNumberValue
          break
        case 'MULTIPLY':
          lastestOperationResult =
            lastestOperationResult * rightVariableNumberValue
          break
        case 'DIVIDE':
          lastestOperationResult =
            lastestOperationResult / rightVariableNumberValue
          break
        default:
          break
      }
    }
  }
  console.log('ค่าท้ายสุดจะเป็น', lastestOperationResult)

  return (
    <Box
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      className=" ml-4 my-2 p-2 w-full  md:w-full  h-64"
    >
      <h5 className="font-bold text-2xl"> {graphLabel} </h5>
      <div>
        <h1 className=" font-bold text-4xl text-blue-500 mb-0 ">
          {' '}
          {parseFloat(lastestOperationResult).toFixed(2)}{' '}
        </h1>
        <h3 className="font-semibold text-lg"> {unit}</h3>
      </div>
    </Box>
  )
}
