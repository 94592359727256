import React from 'react'
import { Button, Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import SensorRequireAttribute from 'views/system/management-statations/Components/SensorRequireAttribute'
export default function CheckBoxForm({
  register,
  inputType,
  setInputType,
  append,
  remove,
  fields,
  inputSensor,
  setInputSensor,
  station,
}) {
  const { t, i18n } = useTranslation()
  const handleRemove = (index) => {
    remove(index)
  }
  return (
    <div className="w-full">
      <div className="w-full px-4">
        <div className="w-full px-4">
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              ชื่อฟอร์ม
            </label>
            <input
              name="name"
              type="text"
              required
              ref={register}
              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              placeholder="Meter"
            />
          </div>
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              ชื่อหัวข้อใหญ่
            </label>
            <input
              name="topic"
              type="text"
              required
              ref={register}
              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              placeholder="มิเตอร์"
            />
          </div>
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              คำอธิบายหัวข้อใหญ่
            </label>
            <input
              name="description"
              type="text"
              ref={register}
              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              placeholder="ใช้สำหรับมิเตอร์"
            />
          </div>
        </div>
      </div>

      {_.map(fields, (row, index) => (
        <Box
          key={row?.id}
          padding="4"
          borderRadius="lg"
          borderWidth="2"
          className="text-sm font-semibold border mb-2"
        >
          <div className="flex justify-between">
            <h5 className="mb-2">หัวข้อย่อยที่ {index + 1} </h5>
            <Button
              colorScheme="red"
              size="sm"
              onClick={() => handleRemove(index)}
            >
              {t('utility.delete')}
            </Button>
          </div>
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              ชื่อหัวข้อย่อย
            </label>
            <input
              name={`attributeArray.${index}.subTopic`}
              type="text"
              required
              ref={register}
              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              placeholder="อุณหภูมิ"
            />
          </div>
          <div className="relative w-full mb-3">
            <label
              className="block uppercase text-gray-700 text-xs font-bold mb-2"
              htmlFor="grid-password"
            >
              คำอธิบายหัวข้อย่อย
            </label>
            <input
              name={`attributeArray.${index}.subDesc`}
              type="text"
              ref={register}
              className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
              placeholder="ใช้สำหรับระบุ..."
            />
          </div>

          <div className="w-full py-2">
            <SensorRequireAttribute
              inputSensor={inputSensor}
              setInputSensor={setInputSensor}
              register={register}
              station={station}
              index={index}
            />
          </div>
        </Box>
      ))}
      <div className="w-full px-4">
        <Button
          isFullWidth
          colorScheme="blue"
          size="sm"
          onClick={() => append({ subTopic: '', inputType: '' })}
        >
          {t('utility.addNewList')}
        </Button>
      </div>
    </div>
  )
}
