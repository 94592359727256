import React from 'react'
import _ from 'lodash'

import Config from '../../../../config'
import VariableMainRendering from '../../../Variables/Function/VariableMainRendering'

export default function DisplayNumberAttribute({ stationData, attributeData }) {
  if (attributeData.dataSource === Config.TypeOfSource.GaugeSource.sensor) {
    const sensorSourceData = _.find(
      stationData?.sensors,
      (sensor) => sensor?.refString === attributeData?.sensorRef
    )
    return (
      <div className=" text-sm">
        {attributeData.label}
        {':'} {parseFloat(sensorSourceData?.numericStatus)?.toFixed(2)}{' '}
        {attributeData.unit}
      </div>
    )
  } else {
    const variableData = VariableMainRendering(
      stationData,
      attributeData.variableId
    )
    return (
      <div className=" text-sm">
        {attributeData.label}
        {':'} {parseFloat(variableData)?.toFixed(2)} {attributeData.unit}
      </div>
    )
  }
}
