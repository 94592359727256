import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/auth'

const firebaseConfig = {
    apiKey: 'AIzaSyDD2sm5QhIs-QT5edpJhvbUuo9_SH_CSRY',
    authDomain: 'eiotsoft-renita.firebaseapp.com',
    databaseURL: 'https://eiotsoft-renita.firebaseio.com',
    projectId: 'eiotsoft-renita',
    storageBucket: 'eiotsoft-renita.appspot.com',
    messagingSenderId: '773168082554',
    appId: '1:773168082554:web:fd6ae476c8dda8de5755c3',
    measurementId: 'G-VWSQKVYXZZ',
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
const app = firebase.app()
export { firebase }
export const auth = firebase.auth()
export const storage = firebase.storage()
export const signInAuthProvider = [
    {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    },
    {
        provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        scopes: ['public_profile', 'email'],
    },
]

console.log(app.name ? 'Firebase Activated!' : 'Firebase not working :(')
