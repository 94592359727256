import React, { useEffect, forwardRef } from 'react'
import PropTypes from 'prop-types'
import {
  Box,
  Button,
  Input,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react'
import { ModalBody } from 'reactstrap'
import { Controller } from 'react-hook-form'
function ModalPeriod({
  isOpen,
  onOpen,
  onClose,
  control,
  form,
  handleSubmit,
  submitedFunction,
}) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Box>
            <div className="p-4">
              <div className="pb-4 ">แก้ไขเวลา</div>
              <div>
                <label
                  className="block uppercase text-gray-700 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  ความถี่ในการบันทึกข้อมูล (นาที)
                </label>
                <Controller
                  name="period"
                  control={control}
                  render={(field) => (
                    <Input
                      {...field}
                      defaultValue={form?.inputWithSensor?.period}
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      placeholder="Time"
                    />
                  )}
                />
              </div>
              <div className="py-4 flex justify-center">
                <Button onClick={handleSubmit(submitedFunction)}>บันทึก</Button>
              </div>
            </div>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

ModalPeriod.propTypes = {}

export default forwardRef(ModalPeriod)
