import React, { useContext, useEffect, useState } from 'react'
import { firebaseAuth } from 'contexts/AuthContext'
import { Link, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../../redux/actions'
import SpinnerComponent from '../../components/Loading/SpinnerLoading'
import logoImage from '../../assets/img/logoImage.png'

export default function Login() {
  const { register, handleSubmit, errors } = useForm()
  const { handleSignin, handleProviderAuth } = useContext(firebaseAuth)
  const dispatch = useDispatch()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const onSubmit = (data, e) => {
    e.preventDefault()
    // console.log("handleSubmit");
    //wait to sigin
    handleSignin(data.email, data.password).then(() => {
      //push home
      history.push('/')
    })
  }
  const authProviderUI = () => {
    return handleProviderAuth()
  }

  useEffect(() => {
    dispatch(actions.systemInfoGet()).then(() => {
      setIsLoading(true)
    })
    return () => {}
  }, [])

  if (isLoading === true)
    return (
      <>
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <div className="rounded-t mb-0 px-6 py-6">
                    <div className="flex justify-center">
                      <img src={logoImage} />
                    </div>
                    <div className="text-center mb-3">
                      <h6 className="text-gray-600 text-sm font-bold">
                        เข้าสู่ระบบ
                      </h6>
                    </div>
                    <hr className="mt-6 border-b-1 border-gray-400" />
                  </div>

                  {/* <div className="text-gray-500 text-center mb-3 font-bold">
                  <small>Or sign in with credentials</small>
                </div> */}
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Email
                      </label>
                      <input
                        name="email"
                        type="email"
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                        ref={register}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Password
                      </label>
                      <input
                        name="password"
                        type="password"
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                        placeholder="Password"
                        ref={register}
                      />
                    </div>

                    <div className="text-center mt-6">
                      <button
                        className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        type="submit"
                      >
                        Sign In
                      </button>
                    </div>
                  </form>

                  <div className="flex justify-center mt-6 relative">
                    <Link
                      to="/auth/forgotten-password"
                      className="text-gray-600"
                    >
                      <small>ลืมรหัสผ่าน ?</small>
                    </Link>
                    {/* <div className="w-1/2 text-right">
                <Link to="/auth/register" className="text-gray-300">
                  <small>สร้างบัญชีใหม่</small>
                </Link>
              </div> */}
                  </div>
                  {/* <hr className="mt-6 border-b-1 border-gray-400" />
                <div className="rounded-t mb-0 px-2 py-2">
                  <div className="text-center mb-3">
                    <h6 className="text-gray-500 text-center mb-3 font-bold">
                      <small> or sign in with </small>
                    </h6>
                  </div>
                  <div className="btn-wrapper text-center">
                    {authProviderUI()}
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  else {
    return <SpinnerComponent />
  }
}
