import React from 'react'
import GaugeEditingComponent from '../common/EditingGaugeComponents'
import ComponentType from '../../../config/ComponentType'
import _ from 'lodash'

export default function EditingGeneralGagueSelector({
  gaugeData,
  register,
  gaugeType,
  stationData,
  setAdditionalInfo,
  sensorType,
  gaugeSource,
}) {
  switch (gaugeType) {
    case ComponentType.GaugeType.MultiStepBooleanWaterDisplay:
      return (
        <GaugeEditingComponent.EditMultiStepWaterDisplay
          gaugeData={gaugeData}
          register={register}
          stationData={stationData}
        />
      )
    case ComponentType.GaugeType.StaticBooleanWater:
      return (
        <GaugeEditingComponent.EditStaticDisplay.StaticBooleanWater
          stationData={stationData}
          gaugeData={gaugeData}
          register={register}
          gaugeSource={gaugeSource}
        />
      )
    case ComponentType.GaugeType.StaticMeter:
      return (
        <GaugeEditingComponent.EditStaticDisplay.StaticMeter
          stationData={stationData}
          gaugeData={gaugeData}
          register={register}
          gaugeSource={gaugeSource}
        />
      )
    case ComponentType.GaugeType.StaticMeterClassic:
      return (
        <GaugeEditingComponent.EditStaticDisplay.StaticMeterClassic
          stationData={stationData}
          gaugeData={gaugeData}
          register={register}
          gaugeSource={gaugeSource}
        />
      )
    case ComponentType.GaugeType.StaticMeterColors:
      return (
        <GaugeEditingComponent.EditStaticDisplay.StaticMeterColors
          stationData={stationData}
          gaugeData={gaugeData}
          register={register}
          gaugeSource={gaugeSource}
        />
      )

    case ComponentType.GaugeType.StaticBoolean:
      return (
        <GaugeEditingComponent.EditStaticDisplay.StaticBoolean
          stationData={stationData}
          gaugeData={gaugeData}
          register={register}
          gaugeSource={gaugeSource}
        />
      )
    case ComponentType.GaugeType.CountingNumber:
      return (
        <GaugeEditingComponent.EditStaticDisplay.CountingNumberDisplay
          stationData={stationData}
          gaugeData={gaugeData}
          register={register}
          gaugeSource={gaugeSource}
        />
      )
    case ComponentType.GaugeType.StaticNumber:
      return (
        <GaugeEditingComponent.EditStaticDisplay.StaticNumber
          stationData={stationData}
          gaugeData={gaugeData}
          register={register}
          gaugeSource={gaugeSource}
        />
      )
    case ComponentType.GaugeType.StaticString:
      return (
        <GaugeEditingComponent.EditStaticDisplay.StaticString
          stationData={stationData}
          gaugeData={gaugeData}
          register={register}
          gaugeSource={gaugeSource}
        />
      )
      {
        /** @readonly since 2021/04/14 */
      }
    case ComponentType.GaugeType.MultiStepNumberDisplay:
      return (
        <GaugeEditingComponent.EditMultiStepNumberDisplay
          gaugeData={gaugeData}
          register={register}
          stationData={stationData}
          setAdditionalInfo={setAdditionalInfo}
          sensorType={sensorType}
        />
      )
    case ComponentType.GaugeType.MultiStepBooleanDisplay:
      return (
        <GaugeEditingComponent.EditMultiStepBooleanDisplay
          gaugeData={gaugeData}
          register={register}
          stationData={stationData}
          setAdditionalInfo={setAdditionalInfo}
          sensorType={sensorType}
        />
      )

      {
        /** @deprecate since 2021/04/14 */
      }
    case ComponentType.GaugeType.Meter:
      return (
        <GaugeEditingComponent.BackwardCompatible.EditMeterData
          stationData={stationData}
          gaugeData={gaugeData}
          register={register}
        />
      )
    case ComponentType.GaugeType.BooleanDisplay:
      return (
        <GaugeEditingComponent.BackwardCompatible.EditBooleanDisplay
          stationData={stationData}
          gaugeData={gaugeData}
          register={register}
        />
      )
    case ComponentType.GaugeType.NumberDisplay:
      return (
        <GaugeEditingComponent.BackwardCompatible.EditNumberDisplay
          stationData={stationData}
          gaugeData={gaugeData}
          register={register}
        />
      )
    case ComponentType.GaugeType.StringDisplay:
      return (
        <GaugeEditingComponent.BackwardCompatible.EditStringDisplay
          stationData={stationData}
          gaugeData={gaugeData}
          register={register}
        />
      )
    case ComponentType.GaugeType.BooleanWaterDisplay:
      return (
        <GaugeEditingComponent.BackwardCompatible.EditBooleanWater
          stationData={stationData}
          gaugeData={gaugeData}
          register={register}
        />
      )
    default:
      return <></>
  }
}
