// HARD CODE
import React from 'react'
import LiquidGauge from 'react-liquid-gauge'
import { Box } from '@chakra-ui/react'
import _ from 'lodash'

export default function StaticBooleanWaterDisplay({
  staticData,
  graphLabel,
  isActiveHigh,
  labelOnLow,
  labelOnHigh,
}) {
  const booleanStatus = staticData

  // Estimate Display Liquid Level
  let liquildLevel
  if (isActiveHigh === true) {
    if (booleanStatus === true) {
      liquildLevel = 90
    } else {
      liquildLevel = 40
    }
  } else {
    if (booleanStatus === false) {
      liquildLevel = 90
    } else {
      liquildLevel = 40
    }
  }

  return (
    <Box
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      className=" ml-4 my-2 p-4 w-full  h-25 "
    >
      <h5 className="font-bold  text-2xl"> {graphLabel} </h5>
      <div className="flex justify-center mt-4 pt-2">
        <LiquidGauge
          value={liquildLevel}
          textRenderer={(val) => {
            if (liquildLevel === 40) return labelOnLow
            else if (liquildLevel === 90) return labelOnHigh
            else return 'ไม่ทราบข้อมูล'
          }}
          riseAnimation
          waveAnimation
          waveFrequency={2}
          waveAmplitude={1}
          gradient
          width={120}
          height={120}
          circleStyle={{ fill: 'lightgrey' }}
        />
      </div>
    </Box>
  )
}
