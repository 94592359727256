import React from 'react'
import PropTypes from 'prop-types'
import { Button, FormControl, FormLabel, Switch, Box } from '@chakra-ui/react'
import _ from 'lodash'
const SensorRequire = ({ inputSensor, setInputSensor, register, station }) => {
  console.log('station', station)
  return (
    <div className="px-4">
      <label
        className="block uppercase text-gray-700 text-xs font-bold mb-2"
        htmlFor="grid-password"
      >
        รับค่าจาก sensor
      </label>
      <Switch
        name="inputSensor.sensor"
        ref={register}
        onChange={(e) => setInputSensor(e.target.checked)}
      />
      {inputSensor && (
        <div className="py-1">
          <select
            name={`inputSensor.sensorId`}
            required
            ref={register}
            //onChange={(event) => setInputType(event.target.value)}
            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
          >
            {_.map(station?.sensors, (eachSensor) => (
              <option value={eachSensor?._id}>{eachSensor?.name}</option>
            ))}
          </select>
        </div>
      )}
    </div>
  )
}

SensorRequire.propTypes = {
  inputSensor: PropTypes.bool,
  setInputSensor: PropTypes.object,
  register: PropTypes.object,
  station: PropTypes.object,
}

export default SensorRequire
