import React from 'react'
import { Box } from '@chakra-ui/react'
export default function StaticBooleanDisplay({
  staticData,
  labelOnHigh = 'True',
  labelOnLow = 'False',
  graphLabel = '',
  highLevelColor = 'green',
  lowLevelColor = 'red',
  favoriteButton,
}) {
  let highValueColorCode = 'text-' + highLevelColor + '-500'
  let lowValueColorCode = 'text-' + lowLevelColor + '-500'
  return (
    <Box
      maxW="sm"
      borderWidth="1px"
      borderRadius="lg"
      className=" ml-4 my-2 p-4 w-full h-25"
    >
      <div className="flex flex-row">
        {favoriteButton}
        <h5 className="font-bold text-2xl"> {graphLabel} </h5>
      </div>
      <div>
        {staticData === true ? (
          <h1 className={' font-bold text-3xl  ' + highValueColorCode}>
            {' '}
            {labelOnHigh}
          </h1>
        ) : (
          <h1 className={' font-bold text-3xl ' + lowValueColorCode}>
            {labelOnLow}{' '}
          </h1>
        )}
      </div>
    </Box>
  )
}
