import React from 'react'
import { Input, InputLeftElement, InputGroup } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
export default function EmloyeeSearch({ data, setData }) {
    const { t } = useTranslation()
    const handleSearch = (word) => {
        console.log(data)
        const res = data.filter(
            (user) => user.name.includes(word) || user.email.includes(word)
        )
        setData(res)
    }
    return (
        <div>
            <InputGroup>
                <InputLeftElement
                    pointerEvents="none"
                    children={
                        <i className="fa fa-search" aria-hidden="true"></i>
                    }
                />
                <Input
                    className="shadow-md"
                    colorScheme="black"
                    placeholder={t('utility.search')}
                    onChange={(e) => {
                        data && handleSearch(e.target.value)
                    }}
                />
            </InputGroup>
        </div>
    )
}
