import React from 'react'
import {
    Table,
    Thead,
    Tr,
    Th,
    Td,
    Tbody,
    Button,
    HStack,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import * as actions from '../../../../redux/actions'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
export default function TemplateList({ templateArray }) {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const handleDeleteTemplate = (templateId) => {
        let confirm = window.confirm('ยืนยันการลบแม่แบบสถานี')
        if (confirm) {
            dispatch(actions.stationTemplateDelete(templateId)).then(() => {
                dispatch(actions.stationTemplateAll())
            })
        }
    }
    const genTemplateArray = () => {
        return _.map(templateArray, (template, index) => (
            <Tr key={index} className="h-16 whitespace-no-wrap">
                <Td>{index + 1}</Td>
                <Td>{template._id}</Td>
                <Td> {template.templateName}</Td>

                <Td>
                    {' '}
                    <HStack spacing="2">
                        {/* <Link to={'/system/template/' + template._id}>
                            <Button colorScheme="yellow" size="sm">
                                แก้ไข
                            </Button>
                        </Link> */}
                        <Link to={'/system/template/sensors/' + template._id}>
                            <Button colorScheme="blue" size="sm">
                                {t('setting.sensor')}
                            </Button>
                        </Link>
                        <Button
                            colorScheme="red"
                            size="sm"
                            onClick={() => handleDeleteTemplate(template._id)}
                        >
                            {t('utility.delete')}
                        </Button>
                    </HStack>
                </Td>
            </Tr>
        ))
    }
    return (
        <div>
            <Table size="sm" variant="striped">
                <Thead>
                    <Tr>
                        <Th>#</Th>
                        <Th>{t('template.codeTemplate')}</Th>
                        <Th>{t('template.nameTemplate')}</Th>
                        <Th> {t('utility.action')}</Th>
                    </Tr>
                </Thead>
                <Tbody>{genTemplateArray()}</Tbody>
            </Table>
        </div>
    )
}
