import React from 'react'
import _ from 'lodash'

import Config from '../../../../config'
import VariableMainRendering from '../../../Variables/Function/VariableMainRendering'

export default function DisplayImageAttribute({
  stationData,
  attributeData,
  diagramData,
}) {
  let displaySize = diagramData?.imageSize / 5
  let type = diagramData?.diagramTypeImage
  console.log('attribule', attributeData.dataSource, attributeData)
  if (attributeData.dataSource === Config.TypeOfSource.GaugeSource.sensor) {
    const sensorSourceData = _.find(
      stationData?.sensors,
      (sensor) => sensor.refString === attributeData.sensorRef
    )
    //console.log('attribule', attributeData)
    if (sensorSourceData) {
      if (attributeData.isActiveHigh === true) {
        return (
          <>
            {sensorSourceData.booleanStatus === true ? (
              <div className="flex justify-center">
                <img
                  src={`/Images/${type}/On` + diagramData.diagramImage}
                  alt={diagramData.diagramName}
                  className={`w-${displaySize} h-20 self-end `}
                />
              </div>
            ) : (
              <div className="flex justify-center">
                <img
                  src={`/Images/${type}/Off` + diagramData.diagramImage}
                  alt={diagramData.diagramName}
                  className={`w-${displaySize} h-20 self-end `}
                />
              </div>
            )}
          </>
        )
      } else {
        return (
          <div>
            {sensorSourceData.booleanStatus === false ? (
              <>
                <img
                  src={`/Images/${type}/Off` + diagramData.diagramImage}
                  alt={diagramData.diagramName}
                  className={`w-${displaySize} h-20 self-end `}
                />
              </>
            ) : (
              <>
                <img
                  src={`/Images/${type}/On` + diagramData.diagramImage}
                  alt={diagramData.diagramName}
                  className={`w-${displaySize} h-20 self-end `}
                />
              </>
            )}
          </div>
        )
      }
    } else {
      return <></>
    }
  } else if (
    attributeData?.dataSource === Config?.TypeOfSource?.GaugeSource?.variable
  ) {
    const variableData = VariableMainRendering(
      stationData,
      attributeData?.variableId
    )
    if (attributeData?.isActiveHigh === true) {
      return (
        <div>
          {variableData === true ? (
            <>
              <img
                src={`/Images/${type}/On` + diagramData.diagramImage}
                alt={diagramData.diagramName}
                className={`w-${displaySize} h-20 self-end `}
              />
            </>
          ) : (
            <>
              <img
                src={`/Images/${type}/Off` + diagramData.diagramImage}
                alt={diagramData.diagramName}
                className={`w-${displaySize} h-20 self-end `}
              />
            </>
          )}
        </div>
      )
    } else {
      return (
        <div>
          {variableData === false ? (
            <>
              <img
                src={`/Images/${type}/Off` + diagramData.diagramImage}
                alt={diagramData.diagramName}
                className={`w-${displaySize} h-20 self-end `}
              />
            </>
          ) : (
            <>
              <img
                src={`/Images/${type}/On` + diagramData.diagramImage}
                alt={diagramData.diagramName}
                className={`w-${displaySize} h-20 self-end `}
              />
            </>
          )}
        </div>
      )
    }
  } else {
    return (
      <>
        {' '}
        <img
          src={`/Images/${type}/` + diagramData.diagramImage}
          alt={diagramData.diagramName}
          className={`w-${displaySize} h-20 self-end `}
        />
      </>
    )
  }
}
