/* eslint-disable prefer-destructuring */
import React from 'react'
import _ from 'lodash'

import Config from '../../../config'
import SensorChart from '../SensorChart'
import VariableChart from '../VariableChart'

export default function ChartRendering({
  chartData,
  data,
  dataPrediction,
  graphMax,
  graphMin,
  setGraphMax,
  setGraphMin,
  setTickAmount,
  setValueFormat,
  tickAmount,
  valueFormat,
  stationData,
  enableTimeSelect = true,
}) {
  // console.log('LOG IN ChartRendering: ', data, chartData?.chartLabel)
  const chartDataSource = chartData.chartSource
  if (chartDataSource === Config.TypeOfSource.GaugeSource.sensor) {
    const chartType = chartData.chartType
    switch (chartType) {
      case Config.ComponentType.ChartType.line:
        return (
          <SensorChart.LineChart
            data={data}
            chartData={chartData}
            stationData={stationData}
            graphMax={graphMax}
            graphMin={graphMin}
            setGraphMax={setGraphMax}
            setGraphMin={setGraphMin}
            setTickAmount={setTickAmount}
            setValueFormat={setValueFormat}
            tickAmount={tickAmount}
            valueFormat={valueFormat}
            enableTimeSelect={enableTimeSelect}
          />
        )
      case Config.ComponentType.ChartType.area:
        return (
          <SensorChart.AreaChart
            data={data}
            chartData={chartData}
            stationData={stationData}
            graphMax={graphMax}
            graphMin={graphMin}
            setGraphMax={setGraphMax}
            setGraphMin={setGraphMin}
            setTickAmount={setTickAmount}
            setValueFormat={setValueFormat}
            tickAmount={tickAmount}
            valueFormat={valueFormat}
            enableTimeSelect={enableTimeSelect}
          />
        )
      case Config.ComponentType.ChartType.combine:
        return (
          <SensorChart.CombineChart
            data={data}
            dataPrediction={dataPrediction}
            chartData={chartData}
            stationData={stationData}
            graphMax={graphMax}
            graphMin={graphMin}
            setGraphMax={setGraphMax}
            setGraphMin={setGraphMin}
            setTickAmount={setTickAmount}
            setValueFormat={setValueFormat}
            tickAmount={tickAmount}
            valueFormat={valueFormat}
            enableTimeSelect={enableTimeSelect}
          />
        )
      default:
        return <div>ไม่มีกราฟแบบที่ท่านต้องการ</div>
    }
  } else {
    //console.log('chartData', chartData)
    const chartVariableId = chartData.chartSensorRef
    const chartType = chartData.chartType
    switch (chartType) {
      case Config.ComponentType.ChartType.line:
        return (
          <VariableChart.LineChart
            data={data}
            chartData={chartData}
            variableId={chartVariableId}
            stationData={stationData}
            graphMax={graphMax}
            graphMin={graphMin}
            setGraphMax={setGraphMax}
            setGraphMin={setGraphMin}
            setTickAmount={setTickAmount}
            setValueFormat={setValueFormat}
            tickAmount={tickAmount}
            valueFormat={valueFormat}
            enableTimeSelect={enableTimeSelect}
          />
        )
      case Config.ComponentType.ChartType.area:
        return (
          <VariableChart.AreaChart
            data={data}
            variableId={chartVariableId}
            chartData={chartData}
            stationData={stationData}
            graphMax={graphMax}
            graphMin={graphMin}
            setGraphMax={setGraphMax}
            setGraphMin={setGraphMin}
            setTickAmount={setTickAmount}
            setValueFormat={setValueFormat}
            tickAmount={tickAmount}
            valueFormat={valueFormat}
            enableTimeSelect={enableTimeSelect}
          />
        )
      case Config.ComponentType.ChartType.combine:
        return (
          <SensorChart.CombineChart
            data={data}
            chartData={chartData}
            stationData={stationData}
            graphMax={graphMax}
            graphMin={graphMin}
            setGraphMax={setGraphMax}
            setGraphMin={setGraphMin}
            setTickAmount={setTickAmount}
            setValueFormat={setValueFormat}
            tickAmount={tickAmount}
            valueFormat={valueFormat}
            enableTimeSelect={enableTimeSelect}
          />
        )

      default:
        return <div>ไม่มีกราฟแบบที่ท่านต้องการ</div>
    }
  }
}
