import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import {
    Button,
    // Checkbox,
    // FormControl,
    // FormLabel,
    // Switch,
} from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import SpinnerLoading from 'components/Loading/SpinnerLoading'
import { useHistory } from 'react-router-dom'
import * as actions from '../../../../redux/actions'
import { useTranslation } from 'react-i18next'
// import hash from "object-hash";

export default function EditConnections({ systemInfo }) {
    const { t } = useTranslation()
    const { register, handleSubmit, reset } = useForm()
    const dispatch = useDispatch()
    const history = useHistory()
    useEffect(() => {
        return () => {}
    }, [])
    const onSubmit = async (data, e) => {
        let confirm = window.confirm('อัพเดทข้อมูล')
        if (confirm) {
            e.preventDefault()
            await dispatch(actions.systemInfoPut(systemInfo._id, data))
            alert('บันทึกสำเร็จ')
        }
    }
    if (systemInfo) {
        return (
            <>
                {' '}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-wrap justify-center pb-8">
                        <div className="w-full lg:w-6/12 px-4">
                            <h6 className="text-gray-500 text-base mt-3 mb-6 font-bold uppercase font-sans ">
                                {t('connection.connectInfo')}
                            </h6>
                            <div className="flex flex-wrap">
                                <div className="w-full px-4">
                                    <div className="flex flex-wrap">
                                        <div className="w-full ">
                                            <div className="relative w-full mb-3 ">
                                                <label
                                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    {t(
                                                        'connection.serverStatus'
                                                    )}{' '}
                                                    {' : '}
                                                    {systemInfo.connection_status ? (
                                                        <span className="text-green-500 text-xs font-bold">
                                                            {t(
                                                                'connection.ready'
                                                            )}
                                                        </span>
                                                    ) : (
                                                        <span className="text-red-500 text-xs font-bold">
                                                            {t(
                                                                'connection.abort'
                                                            )}
                                                        </span>
                                                    )}
                                                </label>
                                                {/* <input
                          name="connection_server"
                          type="text"
                          required
                          ref={register}
                          defaultValue={systemInfo.connection_server}
                          className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                          placeholder="IP Address หรือ โดเมนเนม"
                        /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full px-4">
                                    <div className="flex flex-wrap">
                                        <div className="w-full lg:w-10/12 ">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    {t('connection.server')}
                                                </label>
                                                <input
                                                    name="connection_server"
                                                    type="text"
                                                    required
                                                    ref={register}
                                                    defaultValue={
                                                        systemInfo.connection_server
                                                    }
                                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                    placeholder="IP Address หรือ โดเมนเนม"
                                                />
                                            </div>
                                        </div>
                                        <div className="w-full lg:w-2/12 ">
                                            <div className="relative w-full mb-3">
                                                <label
                                                    className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                                    htmlFor="grid-password"
                                                >
                                                    {t('connection.port')}
                                                </label>
                                                <input
                                                    name="connection_port"
                                                    type="text"
                                                    required
                                                    ref={register}
                                                    defaultValue={
                                                        systemInfo.connection_port
                                                    }
                                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                                    placeholder="พอร์ต"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="w-full px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="grid-password"
                    >
                      หัวข้อ (topic)
                    </label>
                    <input
                      name="connection_topic"
                      type="text"
                      required
                      ref={register}
                      defaultValue={systemInfo.connection_topic}
                      className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                      placeholder="topic"
                    />
                  </div>
                </div> */}

                                <div className="w-full px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            {t('connection.userName')}
                                        </label>
                                        <input
                                            name="connection_user"
                                            type="text"
                                            required
                                            ref={register}
                                            defaultValue={
                                                systemInfo.connection_user
                                            }
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                            placeholder="ชื่อผู้ใช้"
                                        />
                                    </div>
                                </div>

                                <div className="w-full px-4">
                                    <div className="relative w-full mb-3">
                                        <label
                                            className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                            htmlFor="grid-password"
                                        >
                                            {t('connection.password')}
                                        </label>
                                        <input
                                            name="connection_password"
                                            type="password"
                                            required
                                            ref={register}
                                            defaultValue={
                                                systemInfo.connection_password
                                            }
                                            className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                            placeholder="รหัสผ่าน"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="text-center px-4 gap-0 py-3 ">
                                <Button
                                    isFullWidth
                                    colorScheme="blue"
                                    variant="solid"
                                    type="submit"
                                >
                                    {t('utility.save')}{' '}
                                </Button>
                            </div>
                        </div>
                    </div>{' '}
                </form>{' '}
            </>
        )
    } else {
        return <SpinnerLoading />
    }
}
