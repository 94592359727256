/* eslint-disable import/extensions */
import React, { useEffect } from 'react'
import { Select } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from 'redux/actions'
import { useParams } from 'react-router-dom'

export default function InputFormVariableType({
  stationData,
  register,
  index,
  symbol,
  variableData,
}) {
  const { t, i18n } = useTranslation()
  const form = useSelector((state) => state.form)
  const dispatch = useDispatch()

  useEffect(() => {
    if (stationData?._id) {
      dispatch(actions.formAll({ station: stationData?._id }))
    }
    return () => {}
  }, [])

  const options = []

  _.map(form?.arr, (row, index) => {
    _.map(row.components, (comp, i) => {
      if (comp?.formType === 'MULTIPLE_INPUT') {
        _.map(comp?.attribute, (eachAttribute) => {
          options.push({
            value: `${row?._id}/${comp.name}.${eachAttribute.subTopic}`,
            label:
              row?.name + ' : ' + comp.topic + ' - ' + eachAttribute?.subTopic,
          })
        })
      } else {
        options.push({
          value: `${row?._id}/${comp.name}`,
          label: `${row?.name} - ${comp.topic}`,
        })
      }
    })
    _.map(row.subforms, (subForm, i) => {
      _.map(subForm.components, (subComp, ind) => {
        if (subComp?.formType === 'MULTIPLE_INPUT') {
          _.map(subComp?.attribute, (subAttribute) => {
            options.push({
              value: `${subForm?.form_id}/${subComp.name}.${subAttribute.subTopic}/${subForm?._id}`,
              label:
                subForm?.name +
                ' : ' +
                subComp.topic +
                ' - ' +
                subAttribute?.subTopic,
            })
          })
        } else {
          options.push({
            value: `${subForm?.form_id}/${subComp.name}/${subForm?._id}`,
            label: `${subForm?.name} - ${subComp.topic}`,
          })
        }
      })
    })
  })

  return (
    <Select
      defaultValue={
        variableData.valueDetail[Object.keys(variableData.valueDetail)[index]]
      }
      key={index}
      size="sm"
      ref={register}
      name={`value[${index}.${symbol}]`}
    >
      {_.map(options, (each) => (
        <option value={each?.value} key={each?.value}>
          {each?.label}
        </option>
      ))}
    </Select>
  )
}
