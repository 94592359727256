import {
    NumericOperation,
    BooleanNumericOperation,
    BooleanOperation,
} from '../config/MathOperation'
export default function GenerateMathermaticSymbol(stringEquation) {
    switch (stringEquation) {
        case NumericOperation.ADD:
            return '+'
        case NumericOperation.SUBTRACT:
            return '-'
        case NumericOperation.MULTIPLY:
            return '*'
        case NumericOperation.DIVIDE:
            return '/'
        case BooleanOperation.AND:
            return '&&'
        case BooleanOperation.OR:
            return '||'
        case BooleanOperation.NAND:
            return 'NAND'
        case BooleanOperation.NOR:
            return 'NOR'
        case BooleanNumericOperation.EQ:
            return '=='
        case BooleanNumericOperation.GE:
            return '>'
        case BooleanNumericOperation.GEQ:
            return '>='
        case BooleanNumericOperation.LE:
            return '<'
        case BooleanNumericOperation.LEQ:
            return '<='
        case BooleanNumericOperation.NEQ:
            return '!='
        default:
            return ''
    }
}
