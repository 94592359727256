import React, { useState, useEffect } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
    Button,
    Switch,
    Checkbox,
    CheckboxGroup,
    Stack,
    Input,
    InputRightAddon,
    ButtonGroup,
    Text,
} from '@chakra-ui/react'

import _, { truncate } from 'lodash'

export default function DisplayTimeControl({
    sensor,
    onSubmitDay,
    defaultTimer,
}) {
    const { t } = useTranslation()
    const [everday, setEverday] = useState(false)
    const { control, register, handleSubmit, watch, setValue } = useForm({
        defaultValues: { timers: defaultTimer },
    })
    const { fields, append } = useFieldArray({
        control,
        name: 'timers',
    })

    const onSubmit = (data) => {
        console.log('Data', data)
        onSubmitDay(sensor.controlRefString, data)
    }

    //ส่วนของการเพิ่ม-ลด เวลาต่อ 1 เซนเซอร์
    useEffect(() => {
        setValue('timers', defaultTimer)
        console.log('Default', defaultTimer)
        if (_.size(defaultTimer) < 3) {
            const appendSize = 3 - _.size(defaultTimer)
            for (let i = 0; i < appendSize; i++) {
                append({})
            }
        }

        return () => {}
    }, [])

    const handleChangeIncHH = (value) => {
        if (value < 23) {
            return value + 1
        } else {
            return 23
        }
    }

    const handleChangeDecHH = (value) => {
        if (value > 0) {
            return value - 1
        } else {
            return 0
        }
    }

    const handleChangeIncMM = (value) => {
        if (value < 59) {
            return value + 1
        } else {
            return 59
        }
    }

    const handleChangeDecMM = (value) => {
        if (value > 0) {
            return value - 1
        } else {
            return 0
        }
    }

    const handleChangeValueInputMM = (value) => {
        if (value < 59) {
            return value
        } else {
            return 59
        }
    }

    const handleChangeValueInputHH = (value) => {
        if (value < 23) {
            return value
        } else {
            return 23
        }
    }

    const allDay = (index) => {
        const allDayValue = _.values(watch(`timers[${index}].days`))
        if (_.includes(allDayValue, false)) {
            return false
        } else {
            return true
        }
    }
    const allChecked = (index) => {
        if (allDay(index)) {
            return true
        } else if (everday) {
            return true
        } else {
            return false
        }
    }

    const eachTimerControl = (field, index) => (
        <div className="border-2 p-4 lg:flex lg:flex-wrap xl:flex-no-wrap justify-center">
            <div className="w-full flex justify-center gap-10 md:w-full xl:w-1/4 ">
                <div>
                    <Text>
                        {' '}
                        {t('control.timer')} {index + 1}{' '}
                    </Text>
                </div>
                <div>
                    <Switch
                        ref={register}
                        name={`timers[${index}].isTimerEnable`}
                        size="lg"
                        defaultChecked={watch(`timers[${index}].isTimerEnable`)}
                    />
                </div>
            </div>
            <div className="w-full flex flex-no-wrap md:flex-wrap xl:flex-no-wrap ">
                <div className="w-1/2">
                    <div>
                        <Text> {t('dateTime.everyday')}</Text>

                        <Checkbox
                            ref={register}
                            name={`timers[${index}].everyday`}
                            margin={2}
                            size="lg"
                            defaultChecked={allChecked(index)}
                            isChecked={allChecked(index)}
                            //defaultChecked={allDay(index)}
                            //defaultChecked={!watch(`timers[${index}].everday`)}
                            alignContent="center"
                            onChange={(event) => {
                                const currentEveryday = watch(
                                    `timers[${index}].everyday`
                                )

                                setValue(`timers[${index}].days`, {
                                    monday: currentEveryday,
                                    tuesday: currentEveryday,
                                    wednesday: currentEveryday,
                                    thursday: currentEveryday,
                                    friday: currentEveryday,
                                    saturday: currentEveryday,
                                    sunday: currentEveryday,
                                })

                                console.log(
                                    'Watch All value',
                                    watch(`timers[${index}]`)
                                )
                            }}
                            isDisabled={
                                !watch(`timers[${index}].isTimerEnable`)
                            }
                        ></Checkbox>
                    </div>
                    <Stack
                        pl={6}
                        mt={1}
                        spacing={{
                            base: 3,
                            md: 1,
                            lg: 5,
                        }}
                        direction={{
                            base: 'column',
                            md: 'row',
                            lg: 'row',
                        }}
                    >
                        <div align={'center'}>
                            {' '}
                            <Text>{t('dateTime.mon')}</Text>
                            <Checkbox
                                ref={register}
                                name={`timers[${index}].days.monday`}
                                alignContent="center"
                                defaultChecked={watch(
                                    `timers[${index}].days.monday`
                                )}
                                isChecked={watch(
                                    `timers[${index}].days.monday`
                                )}
                                onChange={(event) =>
                                    setValue(
                                        `timers[${index}].days.monday`,
                                        event.target.checked
                                    )
                                }
                                isDisabled={
                                    !watch(`timers[${index}].isTimerEnable`)
                                }
                            >
                                {' '}
                            </Checkbox>
                        </div>
                        <div align={'center'}>
                            <Text>{t('dateTime.tues')}</Text>
                            <Checkbox
                                defaultChecked={watch(
                                    `timers[${index}].days.tuesday`
                                )}
                                ref={register}
                                name={`timers[${index}].days.tuesday`}
                                alignContent="center"
                                isDisabled={
                                    !watch(`timers[${index}].isTimerEnable`)
                                }
                                isChecked={watch(
                                    `timers[${index}].days.tuesday`
                                )}
                                onChange={(event) =>
                                    setValue(
                                        `timers[${index}].days.tuesday`,
                                        event.target.checked
                                    )
                                }
                            ></Checkbox>
                        </div>

                        <div align={'center'}>
                            <Text>{t('dateTime.wednes')}</Text>
                            <Checkbox
                                defaultChecked={watch(
                                    `timers[${index}].days.wednesday`
                                )}
                                ref={register}
                                name={`timers[${index}].days.wednesday`}
                                alignContent="center"
                                isDisabled={
                                    !watch(`timers[${index}].isTimerEnable`)
                                }
                                isChecked={watch(
                                    `timers[${index}].days.wednesday`
                                )}
                                onChange={(event) =>
                                    setValue(
                                        `timers[${index}].days.wednesday`,
                                        event.target.checked
                                    )
                                }
                            ></Checkbox>
                        </div>
                        <div align={'center'}>
                            <Text>{t('dateTime.thurs')}</Text>
                            <Checkbox
                                defaultChecked={watch(
                                    `timers[${index}].days.thursday`
                                )}
                                ref={register}
                                name={`timers[${index}].days.thursday`}
                                alignContent="center"
                                isDisabled={
                                    !watch(`timers[${index}].isTimerEnable`)
                                }
                                isChecked={watch(
                                    `timers[${index}].days.thursday`
                                )}
                                onChange={(event) =>
                                    setValue(
                                        `timers[${index}].days.thursday`,
                                        event.target.checked
                                    )
                                }
                            ></Checkbox>
                        </div>
                        <div align={'center'}>
                            <Text>{t('dateTime.fri')}</Text>
                            <Checkbox
                                defaultChecked={watch(
                                    `timers[${index}].days.friday`
                                )}
                                ref={register}
                                name={`timers[${index}].days.friday`}
                                alignContent="center"
                                isDisabled={
                                    !watch(`timers[${index}].isTimerEnable`)
                                }
                                isChecked={watch(
                                    `timers[${index}].days.friday`
                                )}
                                onChange={(event) =>
                                    setValue(
                                        `timers[${index}].days.friday`,
                                        event.target.checked
                                    )
                                }
                            ></Checkbox>
                        </div>
                        <div align={'center'}>
                            <Text>{t('dateTime.satur')}</Text>
                            <Checkbox
                                defaultChecked={watch(
                                    `timers[${index}].days.saturday`
                                )}
                                ref={register}
                                name={`timers[${index}].days.saturday`}
                                alignContent="center"
                                isDisabled={
                                    !watch(`timers[${index}].isTimerEnable`)
                                }
                                isChecked={watch(
                                    `timers[${index}].days.saturday`
                                )}
                                onChange={(event) =>
                                    setValue(
                                        `timers[${index}].days.saturday`,
                                        event.target.checked
                                    )
                                }
                            ></Checkbox>
                        </div>
                        <div align={'center'}>
                            <Text>{t('dateTime.sun')}</Text>
                            <Checkbox
                                defaultChecked={watch(
                                    `timers[${index}].days.sunday`
                                )}
                                ref={register}
                                name={`timers[${index}].days.sunday`}
                                alignContent="center"
                                isDisabled={
                                    !watch(`timers[${index}].isTimerEnable`)
                                }
                                isChecked={watch(
                                    `timers[${index}].days.sunday`
                                )}
                                onChange={(event) =>
                                    setValue(
                                        `timers[${index}].days.sunday`,
                                        event.target.checked
                                    )
                                }
                            ></Checkbox>
                        </div>
                    </Stack>
                </div>
                <div className="w-full self-center lg:flex justify-center">
                    <div>
                        <div className="flex justify-center">
                            {' '}
                            {t('dateTime.start')}
                        </div>

                        <div className="flex justify-center py-3 ">
                            <div>
                                <Input
                                    value={_.padStart(
                                        watch(`timers[${index}].startHour`),
                                        2,
                                        '0'
                                    )}
                                    onChange={(event) => {
                                        setValue(
                                            `timers[${index}].startHour`,
                                            handleChangeValueInputHH(
                                                event.target.valueAsNumber
                                            )
                                        )
                                    }}
                                    type="number"
                                    ref={register}
                                    name={`timers[${index}].startHour`}
                                    alignContent="center"
                                    isDisabled={
                                        !watch(`timers[${index}].isTimerEnable`)
                                    }
                                    defaultValue={0}
                                    maxWidth={20}
                                />
                            </div>
                            <div className="pl-2">
                                <div>
                                    <Button
                                        type="button"
                                        alignContent="center"
                                        colorScheme="teal"
                                        variant="outline"
                                        size="xs"
                                        onClick={() =>
                                            setValue(
                                                `timers[${index}].startHour`,
                                                handleChangeIncHH(
                                                    parseInt(
                                                        watch(
                                                            `timers[${index}].startHour`
                                                        )
                                                    )
                                                )
                                            )
                                        }
                                        isDisabled={
                                            !watch(
                                                `timers[${index}].isTimerEnable`
                                            )
                                        }
                                    >
                                        +
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        type="button"
                                        colorScheme="red"
                                        variant="outline"
                                        size="xs"
                                        onClick={() =>
                                            setValue(
                                                `timers[${index}].startHour`,
                                                handleChangeDecHH(
                                                    parseInt(
                                                        watch(
                                                            `timers[${index}].startHour`
                                                        )
                                                    )
                                                )
                                            )
                                        }
                                        isDisabled={
                                            !watch(
                                                `timers[${index}].isTimerEnable`
                                            )
                                        }
                                    >
                                        -
                                    </Button>
                                </div>
                            </div>
                            <div className="p-2 px-3">: </div>
                        </div>
                        <div className="flex justify-center">
                            <div>
                                <Input
                                    value={_.padStart(
                                        watch(`timers[${index}].startMinute`),
                                        2,
                                        '0'
                                    )}
                                    onChange={(event) => {
                                        setValue(
                                            `timers[${index}].startMinute`,
                                            handleChangeValueInputMM(
                                                event.target.valueAsNumber
                                            )
                                        )
                                    }}
                                    type="number"
                                    ref={register}
                                    name={`timers[${index}].startMinute`}
                                    alignContent="center"
                                    isDisabled={
                                        !watch(`timers[${index}].isTimerEnable`)
                                    }
                                    defaultValue={0}
                                    placeholder="Basic usage"
                                    maxWidth={20}
                                />
                            </div>
                            <div className="pl-2">
                                <div>
                                    <Button
                                        onClick={() =>
                                            setValue(
                                                `timers[${index}].startMinute`,
                                                handleChangeIncMM(
                                                    parseInt(
                                                        watch(
                                                            `timers[${index}].startMinute`
                                                        )
                                                    )
                                                )
                                            )
                                        }
                                        isDisabled={
                                            !watch(
                                                `timers[${index}].isTimerEnable`
                                            )
                                        }
                                        type="button"
                                        colorScheme="teal"
                                        variant="outline"
                                        size="xs"
                                    >
                                        +
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        onClick={() =>
                                            setValue(
                                                `timers[${index}].startMinute`,
                                                handleChangeDecMM(
                                                    parseInt(
                                                        watch(
                                                            `timers[${index}].startMinute`
                                                        )
                                                    )
                                                )
                                            )
                                        }
                                        isDisabled={
                                            !watch(
                                                `timers[${index}].isTimerEnable`
                                            )
                                        }
                                        type="button"
                                        colorScheme="red"
                                        variant="outline"
                                        size="xs"
                                    >
                                        -
                                    </Button>
                                </div>
                            </div>
                            <div className="p-2">{t('dateTime.clock')}</div>
                        </div>
                    </div>
                    <div>
                        <div className="flex justify-center">
                            {t('dateTime.end')}
                        </div>

                        <div className="flex justify-center py-3">
                            <div>
                                <Input
                                    value={_.padStart(
                                        watch(`timers[${index}].stopHour`),
                                        2,
                                        '0'
                                    )}
                                    onChange={(event) => {
                                        setValue(
                                            `timers[${index}].stopHour`,
                                            handleChangeValueInputHH(
                                                event.target.valueAsNumber
                                            )
                                        )
                                    }}
                                    type="number"
                                    ref={register}
                                    name={`timers[${index}].stopHour`}
                                    alignContent="center"
                                    isDisabled={
                                        !watch(`timers[${index}].isTimerEnable`)
                                    }
                                    defaultValue={0}
                                    placeholder="Basic usage"
                                    maxWidth={20}
                                />
                            </div>
                            <div className="pl-2">
                                <div>
                                    <Button
                                        type="button"
                                        alignContent="center"
                                        colorScheme="teal"
                                        variant="outline"
                                        size="xs"
                                        onClick={() =>
                                            setValue(
                                                `timers[${index}].stopHour`,
                                                handleChangeIncHH(
                                                    parseInt(
                                                        watch(
                                                            `timers[${index}].stopHour`
                                                        )
                                                    )
                                                )
                                            )
                                        }
                                        isDisabled={
                                            !watch(
                                                `timers[${index}].isTimerEnable`
                                            )
                                        }
                                    >
                                        +
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        type="button"
                                        colorScheme="red"
                                        variant="outline"
                                        size="xs"
                                        onClick={() =>
                                            setValue(
                                                `timers[${index}].stopHour`,
                                                handleChangeDecHH(
                                                    parseInt(
                                                        watch(
                                                            `timers[${index}].stopHour`
                                                        )
                                                    )
                                                )
                                            )
                                        }
                                        isDisabled={
                                            !watch(
                                                `timers[${index}].isTimerEnable`
                                            )
                                        }
                                    >
                                        -
                                    </Button>
                                </div>
                            </div>
                            <div className="p-2 px-3">:</div>
                        </div>
                        <div className="flex justify-center">
                            <div>
                                <Input
                                    value={_.padStart(
                                        watch(`timers[${index}].stopMinute`),
                                        2,
                                        '0'
                                    )}
                                    onChange={(event) => {
                                        setValue(
                                            `timers[${index}].stopMinute`,
                                            handleChangeValueInputMM(
                                                event.target.valueAsNumber
                                            )
                                        )
                                    }}
                                    type="number"
                                    ref={register}
                                    name={`timers[${index}].stopMinute`}
                                    alignContent="center"
                                    isDisabled={
                                        !watch(`timers[${index}].isTimerEnable`)
                                    }
                                    defaultValue={0}
                                    placeholder="Basic usage"
                                    maxWidth={20}
                                />
                            </div>
                            <div className="pl-2">
                                <div>
                                    <Button
                                        onClick={() =>
                                            setValue(
                                                `timers[${index}].stopMinute`,
                                                handleChangeIncMM(
                                                    parseInt(
                                                        watch(
                                                            `timers[${index}].stopMinute`
                                                        )
                                                    )
                                                )
                                            )
                                        }
                                        isDisabled={
                                            !watch(
                                                `timers[${index}].isTimerEnable`
                                            )
                                        }
                                        type="button"
                                        colorScheme="teal"
                                        variant="outline"
                                        size="xs"
                                    >
                                        +
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        onClick={() =>
                                            setValue(
                                                `timers[${index}].stopMinute`,
                                                handleChangeDecMM(
                                                    parseInt(
                                                        watch(
                                                            `timers[${index}].stopMinute`
                                                        )
                                                    )
                                                )
                                            )
                                        }
                                        isDisabled={
                                            !watch(
                                                `timers[${index}].isTimerEnable`
                                            )
                                        }
                                        type="button"
                                        colorScheme="red"
                                        variant="outline"
                                        size="xs"
                                    >
                                        -
                                    </Button>
                                </div>
                            </div>
                            <div className="p-2">{t('dateTime.clock')}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex place-items-center sm:flex justify-center my-2">
                <Button
                    type="button"
                    colorScheme="blue"
                    onClick={handleSubmit(onSubmit)}
                >
                    {t('utility.save')}
                </Button>
            </div>
        </div>
    )

    return (
        <div>
            {_.map(fields, (field, index) => (
                <div key={field.id}>{eachTimerControl(field, index)}</div>
            ))}
        </div>
    )
}
